export const checkValue = (value: any) => {
  switch (value) {
    case true:
      return "Yes";
      break;
    case false:
      return "No";
      break;
    case null:
      return "_";
      break;
    default:
      return value;
      break;
  }
};
