import { isSupported } from "firebase/messaging";
import { useEffect } from "react";
import { requestPermission } from "./firebase/firebaseInit";
import { ChakraProvider } from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MainLayout } from "layouts/MainLayout";

const App = () => {
  const initFirebae = async () => {
    const isSupport = await isSupported();
    if (isSupport) {
      requestPermission();
    }
  };
  useEffect(() => {
    initFirebae();
  }, []);

  return (
    <ChakraProvider>
      <MainLayout />
      <ToastContainer />
    </ChakraProvider>
  );
};
export default App;
