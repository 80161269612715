import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { PATH_SIGN_IN, PATH_VERIFICATION_CODE } from "navigation";
import { forgotSchema } from "schema";
import { InputController } from "components/controller/InputController";
import { Button } from "components/button/Button";
import { authAPI } from "api";
import dayjs from "dayjs";
import md5 from "md5";
import { setVerifyMailHash, setForgotEmail } from "store";
import { useCommon } from "hook";

const defaultValues = {
  email: "",
};

type TData = typeof defaultValues;

export default function ForgotPassword() {
  const { AppDispatch, AppNavigate } = useCommon();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(forgotSchema),
    reValidateMode: "onChange",
  });

  const onSubmit = async (data: TData) => {
    try {
      const secretKey = process.env.REACT_APP_SCRET_KEY_SEND_GMAIL;
      const hash = md5(
        data.email + secretKey + dayjs().utc().format("DD/MM/YYYY")
      );
      const res = await authAPI.sendOtp({ ...data, hash });
      AppDispatch(setVerifyMailHash(res.data?.hash));
      AppDispatch(setForgotEmail(data.email));
      AppNavigate(PATH_VERIFICATION_CODE);
    } catch (error) {}
  };

  return (
    <form
      className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10"
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* Sign in section */}
      <div className="mt-[10vh] min-w-[30vw] max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-center text-4xl font-bold text-navy-700 dark:text-white">
          Forgot your password?
        </h4>
        <p className="mb-9 ml-1 text-center text-base text-gray-600">
          Enter your email so that we can send you password reset link
        </p>
        {/* Email */}
        <InputController
          control={control}
          name={"email"}
          id={"email"}
          label={"Email*"}
          placeholder={"mail@simmmple.com"}
          extra="mb-3"
          type={"email"}
          error={errors.email?.message}
        />

        <Button title="Send Email" type="submit" />
        <Link to={PATH_SIGN_IN} className="w-max">
          <div className="mx-auto mt-4 flex h-fit w-fit items-center hover:cursor-pointer">
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.70994 2.11997L2.82994 5.99997L6.70994 9.87997C7.09994 10.27 7.09994 10.9 6.70994 11.29C6.31994 11.68 5.68994 11.68 5.29994 11.29L0.709941 6.69997C0.319941 6.30997 0.319941 5.67997 0.709941 5.28997L5.29994 0.699971C5.68994 0.309971 6.31994 0.309971 6.70994 0.699971C7.08994 1.08997 7.09994 1.72997 6.70994 2.11997V2.11997Z"
                fill="#A3AED0"
              />
            </svg>
            <p className="ml-3 text-sm text-gray-600">Back to Login</p>
          </div>
        </Link>
      </div>
    </form>
  );
}
