import avatar from "assets/img/avatars/logo.svg";
import Dropdown from "components/dropdown";
import { useEffect, useId, useRef, useState } from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import {
  PATH_ADMIN_CONTACT_US,
  PATH_ADMIN_FORWARDER,
  PATH_MAIN,
  PATH_USER_INFO,
} from "navigation";
import { authAPI } from "api";
import {
  logout,
  selectApp,
  setNewNotification,
  useAppDispatch,
  useAppSelector,
} from "store";
import { notificationAPI } from "api/notificationAPI";
import { ENotificationType } from "config";
import { LogApp } from "utils";
import { useToast } from "@chakra-ui/react";
import dayjs from "dayjs";
import { IoClose } from "react-icons/io5";
import { INotification } from "interfaces";
import { produce } from "immer";

const defaultNotiParams = {
  page: 1,
  limit: 5,
  type: "auction",
};
const Navbar = (props: {
  onOpenSidenav: () => void;
  currentRoute: any;
  secondary?: boolean | string;
}) => {
  const { currentRoute } = props;
  const contentRef = useRef(null);
  const dispatch = useAppDispatch();
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [notiParam, setNotiParam] =
    useState<typeof defaultNotiParams>(defaultNotiParams);
  const notiDropdownRef = useRef(null);
  const toast = useToast();

  const { newNotification } = useAppSelector((state) => state.nofitication);
  const hasUnreadNitification = notifications?.some(
    (notification) => !notification?.isRead
  );
  const dispath = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!newNotification) {
      LogApp("New Notification", newNotification);
      showToastNotification(newNotification);
    }
  }, [newNotification]);

  const showToastNotification = (notification: INotification) => {
    const newId = dayjs().valueOf();
    LogApp("Show Notification", dayjs(notification.createdAt));
    setNotifications(
      produce(notifications, (draft) => {
        draft.unshift({
          action: notification?.action,
          body: notification?.body,
          client: notification?.client,
          createdAt: notification?.createdAt,
          data: notification?.data,
          id: notification?.id,
          isRead: notification?.isRead?.toString() === "false" ? false : true,
          referenceUserId: notification?.referenceUserId,
          title: notification?.title,
          type: notification?.type,
          userId: notification?.userId,
          referenceUser: notification?.referenceUser,
        });
      })
    );
    toast({
      position: "top-right",
      duration: 3000,
      id: newId,
      render: () => (
        <div className="relative h-[100px] w-[350px] rounded-md bg-white p-4 shadow-sm ">
          <p className="w-full max-w-[80%]">{notification.title}</p>
          <IoClose
            className="absolute right-4 top-4 cursor-pointer"
            onClick={() => toast.close(newId)}
            size={24}
          />
        </div>
      ),
    });
  };

  const getNotifications = async (controlller: AbortController) => {
    try {
      const res = (await notificationAPI.getList(
        notiParam,
        controlller
      )) as any;

      LogApp("Notificwtion res", res);
      setNotifications((prev: any) =>
        notiParam.page === 1 ? res?.data?.items : [...prev, ...res?.data?.items]
      );
      setTotalPage(res?.data?.totalPage);
    } catch (error) {}
  };

  const markReadNotification = async (notification: any) => {
    try {
      if (!notification?.isRead) {
        await notificationAPI.markAsRead(notification?.id);
        setNotifications((prev: any) =>
          prev.map((item: any) =>
            item.id === notification.id ? { ...item, isRead: true } : item
          )
        );
      }
    } catch (error) {}
  };

  const handleClickNoti = (notification: any) => {
    markReadNotification(notification);

    switch (notification?.action) {
      case ENotificationType.NEW_CONTACT_US:
        {
          if (notification?.data?.contact?.id) {
            navigate(
              `${PATH_ADMIN_CONTACT_US}?id=${notification?.data?.contact?.id}`
            );
            notiDropdownRef.current?.reset();
          }
        }
        break;
      case ENotificationType.DECLINE_OFFER:
        {
          if (notification?.referenceUser?.id) {
            navigate(
              `${PATH_ADMIN_FORWARDER}?id=${notification?.referenceUser?.id}`
            );
            notiDropdownRef.current?.reset();
          }
        }
        break;
      default:
        break;
    }
  };

  const markAllReadNotification = async () => {
    try {
      await notificationAPI.markAllRead();
      setNotifications((prev: any) =>
        prev.map((item: any) => ({ ...item, isRead: true }))
      );
    } catch (error) {}
  };

  const handleLogOut = async () => {
    try {
      await authAPI.logout();
      dispatch(logout());
    } catch (error) {}
  };

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && notiParam.page < totalPage) {
          setNotiParam((prev: any) => ({ ...prev, page: prev.page + 1 }));
        }
      });
    },
    {
      root: null, // Use the viewport as the root
      threshold: 0.5, // Trigger when 50% of the target is visible
    }
  );
  useEffect(() => {
    if (contentRef.current) {
      observer.observe(contentRef.current);
    }
    return () => {
      if (contentRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(contentRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  useEffect(() => {
    const controller = new AbortController();
    getNotifications(controller);
  }, [notiParam]);

  return (
    <nav className="sticky top-0 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-1">
          <Link
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to={PATH_MAIN}
          >
            Home
          </Link>
          <span className="mx-3 text-xl text-navy-700 hover:text-navy-700 dark:text-white">
            {">"}
          </span>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to={currentRoute?.path}
          >
            {currentRoute?.name}
          </Link>
        </div>
        <div className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <p className="cursor-default font-bold capitalize hover:text-navy-700 dark:hover:text-white">
            {currentRoute?.name}
          </p>
        </div>
      </div>

      <div
        className="md:w-[155px ] xl:w-[120px ] relative mt-[3px] flex h-[61px] w-[155px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl
      shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:flex-grow-0
      md:gap-1 xl:gap-2"
      >
        {/* start Notification */}
        <Dropdown
          ref={notiDropdownRef}
          button={
            <p className="relative cursor-pointer">
              <IoMdNotificationsOutline
                className="h-8 w-8 text-gray-600 dark:text-white"
                onMouseDown={() =>
                  setNotiParam((prev: any) => ({ ...prev, page: 1 }))
                }
              />
              {hasUnreadNitification && (
                <div className="absolute -right-2 top-0 mr-2 h-2	w-2 self-start rounded-xl bg-red-500"></div>
              )}
            </p>
          }
          animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          children={
            <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-3  shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[580px]">
              <div className="flex items-center justify-between p-2">
                <p className="text-xl font-bold text-navy-700 dark:text-white">
                  Notification
                </p>
                <button
                  className="pr-2 text-base font-bold text-navy-700 dark:text-white"
                  onClick={() => markAllReadNotification()}
                >
                  Mark all read
                </button>
              </div>

              <div className="h-[80vh] overflow-y-auto">
                {notifications?.map((notification) => {
                  return (
                    <button
                      className="border-stone-200 my-2 flex w-full  items-center border-b-2	border-solid pb-5 pt-3"
                      key={notification.id}
                      onClick={() => handleClickNoti(notification)}
                    >
                      {/* <div className="rounded-x flex h-full w-[60px] items-center justify-center text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 101 101"
                          id="user"
                        >
                          <path d="M50.4 54.5c10.1 0 18.2-8.2 18.2-18.2S60.5 18 50.4 18s-18.2 8.2-18.2 18.2 8.1 18.3 18.2 18.3zm0-31.7c7.4 0 13.4 6 13.4 13.4s-6 13.4-13.4 13.4S37 43.7 37 36.3s6-13.5 13.4-13.5zM18.8 83h63.4c1.3 0 2.4-1.1 2.4-2.4 0-12.6-10.3-22.9-22.9-22.9H39.3c-12.6 0-22.9 10.3-22.9 22.9 0 1.3 1.1 2.4 2.4 2.4zm20.5-20.5h22.4c9.2 0 16.7 6.8 17.9 15.7H21.4c1.2-8.9 8.7-15.7 17.9-15.7z"></path>
                        </svg>
                      </div> */}

                      <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                        <div className="flex items-center gap-1">
                          {!notification.isRead && (
                            <div className="mb-1 mr-2 h-2 w-2 shrink-0 rounded-xl bg-red-500"></div>
                          )}
                          <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                            {notification?.title || ""}
                          </p>
                        </div>
                        <p className="font-base text-left text-sm text-gray-900 dark:text-white">
                          {notification?.body || ""}
                        </p>
                        <p className="text-right text-gray-700">
                          {dayjs(notification.createdAt).format(
                            "hh:mm, MMM DD YYYY"
                          )}
                        </p>
                      </div>
                    </button>
                  );
                })}
                <div ref={contentRef} className="h-0.5">
                  {/* <LoadingEllipsis /> */}
                </div>
              </div>
            </div>
          }
          classNames={"py-2 top-6 -left-[230px] md:-left-[550px] w-max"}
        />
        <Dropdown
          button={
            <img
              className="h-10 w-10 cursor-pointer rounded-full"
              src={avatar}
              alt="Elon Musk"
            />
          }
          children={
            <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="m-4 flex flex-col">
                <Link
                  to={PATH_USER_INFO}
                  className=" text-sm text-gray-800 hover:text-brand-500 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </Link>
                <button
                  className=" mt-3 cursor-pointer text-sm font-medium text-red-500 hover:text-brand-500 hover:text-red-500"
                  onClick={handleLogOut}
                >
                  Log Out
                </button>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
    </nav>
  );
};

export default Navbar;
