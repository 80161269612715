export enum EExample {}
export enum appLanguageEnum {
  CZ = "cz",
  EN = "en",
}

export enum borderRadiusEnum {
  NONE = 0,
  SMALL = 4,
  MEDIUM = 8,
  LARGE = 16,
}

export enum ECurrency {
  USD = "USD",
  EUR = "EUR",
  CZK = "CZK",
}

export enum ECurrencyCharacter {
  USD = "$",
  EUR = "€",
  CZK = "Kč",
}

export enum ENotificationType {
  DECLINE_OFFER = "decline_offer",
  NEW_CONTACT_US = "new_contact_us",
}

export enum ESortType {
  ASC = "ASC",
  DESC = "DESC",
}
export type ECurrencyCharacterKeys = keyof typeof ECurrencyCharacter;
