import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { InputController } from "components/controller/InputController";
import { changePassword } from "schema";
import { Button } from "components/button/Button";
import { authAPI } from "api";
import { PATH_SIGN_IN, PATH_USER_INFO } from "navigation";
import { showAppToast } from "utils";
import { logout, useAppDispatch } from "store";

const defaultValues = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

type TData = typeof defaultValues;

const EditPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(changePassword),
    mode: "all",
  });

  const onSubmit = async (data: TData) => {
    try {
      const { confirmPassword, ...payload } = data;
      await authAPI.changePassword(payload);
      showAppToast("Change password successfully", "success");
      dispatch(logout());
      navigate(PATH_SIGN_IN);
    } catch (error) {}
  };
  return (
    <div className="col-span-2">
      <div className="flex h-full w-full  px-2 md:mx-0 md:px-0 lg:mb-10">
        {/* Sign in section */}
        <div className=" min-w-[30vw] max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <h4 className="mb-2.5 text-center text-4xl font-bold text-navy-700 dark:text-white">
            Change password
          </h4>
          <p className="mb-9 ml-1 text-center text-base text-gray-600">
            Please kindly set your new password.
          </p>
          {/* Email */}
          <InputController
            control={control}
            name={"oldPassword"}
            label={"Current password"}
            placeholder={"Current password"}
            extra="mb-3 max-w-lg"
            error={errors.oldPassword?.message}
            type="password"
          />

          {/* Password */}
          <InputController
            control={control}
            name={"newPassword"}
            id={"newPassword"}
            label={"New password"}
            placeholder={"Min. 8 characters"}
            extra="mb-3 max-w-lg"
            type={"password"}
            error={errors.newPassword?.message}
          />

          {/* Password */}
          <InputController
            control={control}
            name={"confirmPassword"}
            id={"confirmPassword"}
            label={"Re-enter new password"}
            placeholder={"Min. 8 characters"}
            extra="mb-3 max-w-lg"
            type={"password"}
            error={errors.confirmPassword?.message}
          />

          <div className="flex">
            <Button
              title="Cancel"
              className="bg-transparent mx-2	"
              onClick={() => navigate(PATH_USER_INFO)}
            />
            <Button
              title="Save changes"
              className="mx-2"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPassword;
