import InputField from "components/fields/InputField";
import { Controller, FieldValues, FieldPath } from "react-hook-form";
import { InputControllerProps, InputFieldProps } from "./type";

export const InputController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: InputControllerProps<TFieldValues, TName>
) => {
  const {
    control,
    name,
    rules,
    defaultValue,
    disabledController,
    shouldUnregister,
    inputType,
    ...restProps
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      disabled={disabledController}
      shouldUnregister={shouldUnregister}
      render={({ field }) => {
        switch (inputType) {
          case "inputField":
            return (
              <InputField
                onChange={(e) => field.onChange(e.target.value)}
                value={field.value}
                {...(restProps as Omit<
                  InputFieldProps,
                  "inputType" | "onChange" | "value"
                >)}
              />
            );
          default:
            return (
              <InputField
                onChange={(e) => field.onChange(e.target.value)}
                value={field.value}
                {...(restProps as Omit<
                  InputFieldProps,
                  "inputType" | "onChange" | "value"
                >)}
              />
            );
        }
      }}
    />
  );
};
