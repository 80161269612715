import { API_ENDPOINT } from "enum";
import { AxiosService } from "./axiosService";

interface IParamUploadTermAndCondition {
  termAndCondition: string;
}

export const staticArticleApi = {
  uploadTermAndCondition: (payload: IParamUploadTermAndCondition) => {
    const url = API_ENDPOINT.STATIC_ARTICLE_TERM_AND_CONDITION;
    return AxiosService.post(url, payload);
  },
  getTermAndCondition: () => {
    const url = API_ENDPOINT.STATIC_ARTICLE_TERM_AND_CONDITION;
    return AxiosService.get<{
      termAndCondition: string;
    }>(url);
  },
};
