import React from "react";
import { Routes, Route, Navigate, useLocation, Link } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import { profileRoutes } from "navigation/routes";
import userIcon from "assets/img/profile/user.png";
import lockIcon from "assets/img/profile/lock.png";
import Card from "components/card";
import { AuthRoute } from "layouts/route/AuthRoute";

export default function Profile(props: { [x: string]: any }) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Profile Settings");

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(profileRoutes);
  }, [location.pathname]);

  const getActiveRoute = (routes: RoutesType[]): string | boolean => {
    let activeRoute = "Profile Settings";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/profile") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              currentRoute={currentRoute}
              secondary={getActiveNavbar(profileRoutes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Card extra="!p-[20px]">
                <div className="grid grid-flow-col grid-cols-3">
                  <div>
                    <Link
                      to="/profile/user-info"
                      className={`mt-4 flex items-center ${
                        location.pathname === "/profile/user-info" &&
                        "text-brand-500"
                      }`}
                    >
                      <img src={userIcon} alt="" />
                      <span className="ml-4">User info</span>
                    </Link>
                    <Link
                      to="/profile/password"
                      className={`mt-4 flex items-center ${
                        location.pathname === "/profile/password" &&
                        "text-brand-500"
                      }`}
                    >
                      <img src={lockIcon} alt="" />
                      <span className="ml-4">Password</span>
                    </Link>
                  </div>
                  <div className="col-span-2">
                    <Routes>
                      {/* Auth Route */}
                      {getRoutes(profileRoutes)}

                      <Route
                        path="/"
                        element={<Navigate to="/profile/user-info" replace />}
                      />
                    </Routes>
                  </div>
                </div>
              </Card>
            </div>
            {/* <div className="p-3">
              <Footer />
            </div> */}
          </div>
        </main>
      </div>
    </div>
  );
}
