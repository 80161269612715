import { EPackageType, ETransportType } from "enum";

export const defaultParams = {
  page: 1,
  limit: 10,
  searchKey: null as any,
  transportTypes: [] as any,
  status: "",
  readyDateStart: null as any,
  readyDateEnd: null as any,
};