import * as yup from "yup";

import {
  confirmPassword,
  confirmPasswordSchema,
  emailSchema,
  haveCharacterAndNumberPasswordSchema,
  otpSchema,
  requireTextSchema,
  strongPasswordSchema,
} from "./schema";

export const loginSchema = yup.object().shape({
  email: emailSchema(),
  password: requireTextSchema(),
});
export const changePassword = yup.object().shape({
  oldPassword: requireTextSchema(),
  newPassword: strongPasswordSchema(),
  confirmPassword: confirmPassword("newPassword"),
});
export const forgotSchema = yup.object().shape({
  email: emailSchema(),
});

export const verificationSchema = yup.object().shape({
  otp: otpSchema(),
});

export const resetPasswordSchema = yup.object().shape({
  password: strongPasswordSchema(),
  confirmPassword: confirmPasswordSchema(),
});
