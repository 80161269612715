import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "store";
import App from "./App";
import { ThemeProvider } from "styled-components";
import { StyledTheme } from "themes/StyledTheme";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={StyledTheme}>
        <App />
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
);
