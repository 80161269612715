import { DATE_FORMAT } from "config";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const formatAppDate = (date?: any, endFormat?: string) => {
  if (!date) return "";
  if ((date as Dayjs)?.format)
    return (date as Dayjs).format(endFormat || DATE_FORMAT);
  return dayjs(date).format(endFormat || DATE_FORMAT) || "";
};
export const getUTCTime = (time: any, keepLocalTime?: boolean) => {
  return dayjs(time).utc(keepLocalTime);
};
