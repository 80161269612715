export const defaultParams: {
  page: number;
  limit: number;
  search: string;
  endDate?: any;
  startDate?: any;
} = {
  page: 1,
  limit: 10,
  search: "",
};
