export const API_ENDPOINT = {
  // Login
  SIGN_IN: "/auth/admin/login",
  SIGN_UP: "/auth/sign-up",
  FORGOT_PASSWORD: "/auth/forgot-password",
  SEND_OTP: "/admin/send-otp",
  VERIFY_OTP: "/auth/verify-otp",
  CHANGE_PASSWORD: "/admin/change-password",
  RESET_PASSWORD: "/auth/admin/forgot-password",
  LOGOUT: "/auth/logout",

  // profile
  PROFILE: "admin/profile",

  // manufacturer
  MANUFACTURER: "admin/manufactures",

  // company industry
  COMPANY_INDUSTRY: "/company-industry",

  // forwarder
  FORWARDER: "admin/forwarders",

  // auction
  AUCTION: "admin/auctions",

  //invoice
  INVOICE: "admin/invoices",

  //contact us
  CONTACT_US: "contact-us",

  // country
  COUNTRY: "/country",

  // notification
  NOTIFICATION: "admin/notifications",
  NOTIFICATION_MARK_UNREAD: "admin/notifications/unread-number",

  // upload file
  UPLOAD_FILE: "/upload",

  // dashboard
  DASHBOARD_STATISTIC: "/admin/statistic",
  DASHBOARD_STATISTIC_DESTINATION: "/admin/statistic/destinations",

  // currency
  CURRENCY: "currencies",

  // static article
  STATIC_ARTICLE_TERM_AND_CONDITION: "/admin/terms-and-conditions",
  REGISTER_FIREBASE_TOKEN: "/devices/admin/register",
};
