import { Button } from "@chakra-ui/react";
import { staticArticleApi } from "api";
import { AppRemoteUpload } from "components/upload/AppRemoteUpload";
import { useEffect, useRef, useState } from "react";
import { setLoading, useAppDispatch } from "store";
import styled from "styled-components";
import { LogApp, showAppToast } from "utils";

export const StaticArticle = () => {
  const [termAndCondition, setTermAndCondition] = useState<string>("");
  const appRemoteUploadRef = useRef<any>(null);

  const dispatch = useAppDispatch();

  const onChangeFile = (data: any) => {
    setTermAndCondition(data?.[0]?.url);
  };

  const getTermAndCondition = async () => {
    try {
      LogApp("GET TERM");
      const res = await staticArticleApi.getTermAndCondition();
      console.log("RES GET TERM", res);
      appRemoteUploadRef.current?.setFiles([
        {
          title: "Term and condition",
          url: res?.data?.termAndCondition,
        },
      ]);
    } catch (error) {
      LogApp("ERROR GET TERM", error);
    }
  };

  useEffect(() => {
    getTermAndCondition();
  }, []);

  const handleUpload = async () => {
    try {
      dispatch(setLoading(true));
      await staticArticleApi.uploadTermAndCondition({ termAndCondition });
      showAppToast("Upload term and condition successfully", "success");
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  return (
    <Wrapper>
      <div className="term-and-condition">
        <h2 className="title">Terms and conditions</h2>
        <div className="upload-section">
          <div className="upload-wrapper">
            <p className="upload-wrapper__title">Terms and conditions upload</p>
            <AppRemoteUpload
              ref={appRemoteUploadRef}
              label=""
              desc="Drop PDF file here or click to upload"
              title=""
              accept={[["application/pdf", [".pdf"]]]}
              uploadType="document"
              onChangeListFile={onChangeFile}
              replaceEnable={true}
            />
            <div className="upload-btn-wrapper">
              <Button
                colorScheme="blue"
                onClick={() => handleUpload()}
                mt={5}
                isDisabled={!termAndCondition}
              >
                Upload
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .term-and-condition {
    background-color: #fff;
    border-radius: 1rem;
    padding: 1rem 2rem;

    .title {
      font-size: 24px;
      font-weight: 600;
    }
    .upload-section {
      display: flex;
      .upload-wrapper {
        max-width: 50rem;
        width: 100%;
        margin: 2rem auto;
        padding: 1rem 2rem;
        border-radius: 1rem;
        background-color: #fff;
        border: 1px solid #ccc;

        .upload-wrapper__title {
          font-size: 18px;
          font-weight: 600;
        }

        .app-upload {
          margin-top: 4rem;
          margin-bottom: 1rem;
        }
        .upload-btn-wrapper {
          text-align: end;
        }
      }
    }
  }
`;
