// Admin Imports
import Dashboard from "views/admin/dashboard";
// Auth Imports
import SignIn from "views/auth/SignIn";
import ForgotPassword from "views/auth/ForgotPassword";
import VerificationCode from "views/auth/VerificationCode";
import ResetPassword from "views/auth/ResetPassword";
import ResetPasswordSuccess from "views/auth/ResetPasswordSuccess";

// Profile Imports
import EditPassword from "views/profile/EditPassword";

// Icon Imports
import {
  MdBarChart,
  MdOutlineDashboard,
  MdAttachMoney,
  MdMailOutline,
} from "react-icons/md";
import UserInfo from "views/profile/UserInfo";
import {
  IoBarChartOutline,
  IoNewspaperOutline,
  IoSettingsOutline,
  IoMapOutline,
} from "react-icons/io5";
import { Manufacturer } from "views/admin/manufacture";
import { Forwarder } from "views/admin/forwarder";
import { Invoice } from "views/admin/invoice";
import { StaticArticle } from "views/admin/staticArticle";
import { AuctionPage } from "views/admin/auction";
import { AuctionDetailPage } from "views/admin/auction/auctionDetail";
import { ContactUs } from "views/admin/contactUs";

export interface IRoute {
  name: string;
  layout: string;
  path: string;
  component: JSX.Element;
  secondary?: boolean;
  icon?: JSX.Element;
}

export const routes: IRoute[] = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "dashboard",
    component: <Dashboard />,
  },
  {
    name: "Shipper",
    layout: "/admin",
    path: "manufacturer",
    component: <Manufacturer />,
    secondary: true,
  },
  {
    name: "Forwarder",
    layout: "/admin",
    path: "forwarder",
    icon: <MdBarChart className="h-6 w-6" />,
    component: <Forwarder />,
  },
  {
    name: "Auction",
    layout: "/admin",
    path: "auction",
    icon: <MdBarChart className="h-6 w-6" />,
    component: <AuctionPage />,
  },
  {
    name: "Auction detail",
    layout: "/admin",
    path: "auction/:id",
    icon: <MdBarChart className="h-6 w-6" />,
    component: <AuctionDetailPage />,
  },
  {
    name: "Invoice",
    layout: "/admin",
    path: "invoice",
    component: <Invoice />,
  },
  {
    name: "Static article",
    layout: "/admin",
    path: "static-article",
    component: <StaticArticle />,
  },
  {
    name: "Contact us",
    layout: "/admin",
    path: "contact-us",
    component: <ContactUs />,
  },
];

export const authRoutes = [
  {
    layout: "/auth",
    path: "sign-in",
    component: <SignIn />,
  },
  {
    layout: "/auth",
    path: "forgot-password",
    component: <ForgotPassword />,
  },
  {
    layout: "/auth",
    path: "verification",
    component: <VerificationCode />,
  },
  {
    layout: "/auth",
    path: "reset-password",
    component: <ResetPassword />,
  },
  {
    layout: "/auth",
    path: "reset-password-success",
    component: <ResetPasswordSuccess />,
  },
];

//SIBE BAR ROUTES
export const sideBarRoutes: RoutesType[] = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <IoNewspaperOutline className="h-6 w-6" />,
  },
  {
    name: "Shipper",
    layout: "/admin",
    path: "manufacturer",
    icon: <MdOutlineDashboard className="h-6 w-6" />,
    secondary: true,
  },
  {
    name: "Forwarder",
    layout: "/admin",
    icon: <IoBarChartOutline className="h-6 w-6" />,
    path: "forwarder",
  },
  {
    name: "Auction",
    layout: "/admin",
    icon: <IoMapOutline className="h-6 w-6" />,
    path: "auction",
  },
  {
    name: "Invoice",
    layout: "/admin",
    path: "invoice",
    icon: <MdAttachMoney className="h-6 w-6" />,
  },
  {
    name: "Static article",
    layout: "/admin",
    path: "static-article",
    icon: <IoSettingsOutline className="h-6 w-6" />,
  },
  {
    name: "Contact us",
    layout: "/admin",
    path: "contact-us",
    icon: <MdMailOutline className="h-6 w-6" />,
  },
];

export const profileRoutes = [
  // Profile route
  {
    name: "Password",
    layout: "/profile",
    path: "/user-info",
    component: <UserInfo />,
  },
  {
    name: "Password",
    layout: "/profile",
    path: "/password",
    component: <EditPassword />,
  },
];
