import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { type RootState } from "../../store";
import { appLanguageEnum } from "config";
import { EPackageType, ETransportType } from "enum";

interface IAuctionState {
  filterParams: IFilterParams;
}

interface IFilterParams {
  page: number;
  limit: number;
  searchKey: string;
  transportTypes: ETransportType[keyof ETransportType][];
  packageTypes: ETransportType[keyof EPackageType][];
  status: string;
  readyDateStart: string;
  readyDateEnd: string;
}

const initialState: IAuctionState = {
  filterParams: {
    page: 1,
    limit: 10,
    searchKey: null as any,
    transportTypes: [
      ETransportType.ocean,
      ETransportType.air,
      ETransportType.truck,
      ETransportType.rail,
    ],
    packageTypes: [
      EPackageType.FCL,
      EPackageType.LCL,
      EPackageType.BREAK_BULK,
      EPackageType.NOT_SURE,
    ],
    status: "",
    readyDateStart: "",
    readyDateEnd: "",
  },
};

const appSlice = createSlice({
  name: "auction",
  initialState,
  reducers: {
    setFilterParams: (state, action: PayloadAction<IFilterParams>) => {
      state.filterParams = action.payload;
    },
  },
});

export const selectApp = (state: RootState) => state.app;
export const selectAppLoading = (state: RootState) => state.app?.loading;

export const { setFilterParams } = appSlice.actions;
export default appSlice.reducer;
