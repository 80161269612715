import { Button } from "components/button/Button";
import InputField from "components/fields/InputField";
import { Pagination } from "components/pagination/Pagination";
import { TableLayout } from "components/table/TaybleLayout";
import { TiPlus } from "react-icons/ti";
import { manufactureAPI } from "api/manufactureAPI";
import { useEffect, useState } from "react";
import { IManufactureData, IManufactureDetail, ITableColumn } from "interfaces";
import { IoEyeOutline } from "react-icons/io5";
import { FormModal } from "./FormModal";
import { LogApp, handleFullName, showAppToast } from "utils";
import { defaultParams } from "./defaultValue";
import { IStatusKey, STATUS, STATUS_COLOR } from "enum";
import { Stack } from "@chakra-ui/layout";
import { PopoverBody } from "@chakra-ui/popover";
import { RadioGroup, Radio } from "@chakra-ui/radio";
import PopoverHorizon from "components/popover";
import { Badge } from "@chakra-ui/react";
import { setLoading, useAppDispatch } from "store";

export const Manufacturer = () => {
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [manufactures, setManufactures] = useState<IManufactureData[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalResult, setTotalResult] = useState<number>();
  const [params, setParams] = useState<any>(defaultParams);
  const [manufactureDetail, setManufactureDetail] =
    useState<IManufactureDetail>();
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [mode, setMode] = useState<"new" | "detail">();
  const [companyIndustries, setCompanyIndustries] = useState<any>();

  const dispatch = useAppDispatch();

  const colums: ITableColumn<IManufactureData>[] = [
    {
      key: "id",
      title: "No",
      render: (_, val: any) => (
        <div>{val.index + 1 + (params.page - 1) * 10}</div>
      ),
    },
    {
      key: "name",
      title: "Representative name",
    },
    {
      key: "companyName",
      title: "Company name",
    },
    {
      key: "email",
      title: "Email",
    },
    {
      key: "status",
      title: "Status",
      render: (val: String) => (
        <>
          <Badge
            colorScheme={
              STATUS_COLOR[`${val?.toUpperCase() as keyof typeof STATUS_COLOR}`]
            }
          >
            {val}
          </Badge>
        </>
      ),
    },
    {
      key: "none",
      title: "Action",
      render: (_, val2) => (
        <IoEyeOutline
          className="ml-3 cursor-pointer"
          onClick={() => handleViewDetail(val2)}
        />
      ),
    },
  ];

  const statuses = Object.keys(STATUS)
    .filter((status) => status !== "frozen")
    .map((key: string) => ({
      label: STATUS[key as IStatusKey],
      value: STATUS[key as IStatusKey],
    }));

  const onAddNew = async () => {
    await getCompanyIndustries();
    setMode("new");
    setOpenModal(true);
  };

  const getManufactures = async () => {
    try {
      setLoadingTable(true);
      const res = (await manufactureAPI.getList(params)) as any;
      if (res?.success) {
        setManufactures(
          res.data.items.map((item: any, index: number) => ({
            id: item.id || "",
            index: ++index,
            name: handleFullName(item.firstName, item.lastName),
            companyName: item.companyName || "",
            email: item.email || "",
            status: item.status,
          }))
        );
        setTotalPage(+res.data.totalPage);
        setTotalResult(res.data.total);
      }
      setLoadingTable(false);
    } catch (error) {
      LogApp(error);
      setLoadingTable(false);
    }
  };

  const handleViewDetail = async (value: IManufactureData) => {
    try {
      const res = await Promise.all([
        manufactureAPI.getDetal(value.id),
        getCompanyIndustries(),
      ]);
      const data = res[0]?.data as any;

      if (res[0]?.success && data) {
        setManufactureDetail({
          id: data?.id || "",
          firstName: data?.firstName || "",
          lastName: data?.lastName || "",
          companyPhoneNumber: data?.companyPhoneNumber || "",
          email: data?.email || "",
          companyName: data?.companyName || "",
          companyVatId: data?.companyVatId || "",
          companyIndustryOther: data?.companyIndustryOther || "",
          companyIndustryId: data?.companyIndustry
            ? {
                value: data?.companyIndustry?.id,
                label: data?.companyIndustry?.nameEn,
              }
            : { value: "", label: "Select industry" },

          status: {
            label: data?.status,
            value: data?.status,
          },
          companyLogo: data?.companyLogo
            ? [
                {
                  title: data?.companyLogoTitle || "",
                  url: data?.companyLogo || "",
                },
              ]
            : [],
          companyRegistrations: data.companyRegistrations?.map((item: any) => ({
            title: item?.title || "",
            url: item?.documentUrl || "",
          })),
        });

        setMode("detail");
        setOpenModal(true);
      }
    } catch (error) {}
  };

  const handleAddNew = async (value: IManufactureDetail) => {
    try {
      dispatch(setLoading(true));
      const payload = {
        lastName: value?.lastName,
        firstName: value?.firstName,
        companyPhoneNumber: value?.companyPhoneNumber,
        email: value?.email,
        companyName: value?.companyName,
        status: value?.status.value,
      };
      await manufactureAPI.addNew(payload);
      await getManufactures();
      showAppToast("Sucess", "success");
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      return Promise.reject();
    }
  };

  const handleUpdateManufacture = async (value: IManufactureDetail) => {
    try {
      dispatch(setLoading(true));
      const payload = {
        lastName: value?.lastName,
        firstName: value?.firstName,
        companyPhoneNumber: value?.companyPhoneNumber,
        email: value?.email,
        companyName: value?.companyName,
        companyVatId: value?.companyVatId,
        companyIndustryId: +value?.companyIndustryId.value,
        status: value?.status.value,
        companyLogo: value.companyLogo?.[0]?.url,
        companyLogoTitle: value.companyLogo?.[0]?.title,
        companyIndustryOther:
          value?.companyIndustryId.label === "Others"
            ? value?.companyIndustryOther
            : "",
      } as any;
      if (value.companyRegistrations.length) {
        payload.companyRegistrationDocuments = value.companyRegistrations?.map(
          (item: any) => ({
            title: item?.title,
            documentUrl: item?.url,
          })
        );
      }
      await manufactureAPI.update(value.id, payload);
      await getManufactures();
      showAppToast("Sucess", "success");
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      return Promise.reject();
    }
  };

  const getCompanyIndustries = async () => {
    try {
      const res = (await manufactureAPI.getListIndustry()) as any;
      if (res?.success) {
        setCompanyIndustries(
          res?.data?.map((item: any) => ({
            ...item,
            value: item.id,
            label: item.name,
          }))
        );
      }
    } catch (error) {}
  };

  const filterByStatus = (value: any) => {
    setLoadingTable(true);
    setManufactures([]);
    setParams((prev: any) => ({ ...prev, status: value, page: 1 }));
  };

  const handleSearch = (e: any) => {
    setLoadingTable(true);
    setManufactures([]);
    setParams((prev: any) => ({
      ...prev,
      search: e?.target?.value || "",
      page: 1,
    }));
  };

  const handlePageChange = (e: any) => {
    setLoadingTable(true);
    setManufactures([]);
    setParams((prev: any) => ({ ...prev, page: e.selected + 1 }));
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      getManufactures();
    }, 500);

    return () => clearTimeout(getData);
  }, [params]);

  return (
    <div>
      <div>
        <div className="h-full w-full">
          <div className="mb-4 flex w-full flex-row items-end justify-between">
            <div className="flex items-center">
              <div className="flex">
                <InputField
                  id="Search"
                  shouldClear={true}
                  placeholder="Search"
                  className="min-w-[360px] max-w-[30%] border-gray-600"
                  onChange={handleSearch}
                />
                <PopoverHorizon
                  trigger={
                    <button className="mx-2 mt-2 rounded-xl border border-gray-600 p-2 font-bold">
                      Filter
                    </button>
                  }
                  renderContent={(onClose) => {
                    return (
                      <PopoverBody>
                        <p className="mb-2 font-bold">Status:</p>
                        <RadioGroup
                          onChange={filterByStatus}
                          value={params.status}
                          onClick={onClose}
                        >
                          <Stack spacing={2} direction="row">
                            <Radio value="">All</Radio>
                            <Radio value={STATUS.active}>Active</Radio>
                            <Radio value={STATUS.inactive}>Inactive</Radio>
                            <Radio value={STATUS.frozen}>Frozen</Radio>
                          </Stack>
                        </RadioGroup>
                      </PopoverBody>
                    );
                  }}
                />
              </div>
            </div>
            <Button
              title="Add Shipper"
              className="h-[44px] max-w-[200px]"
              icon={<TiPlus className="mr-1" />}
              onClick={onAddNew}
            />
          </div>
          <TableLayout
            columns={colums}
            data={manufactures}
            onDoubleClick={handleViewDetail}
            loading={loadingTable}
          />
          {!!totalResult && !loadingTable && (
            <div className="mx-3 flex items-center justify-between">
              <div>
                {totalResult > 1
                  ? `${totalResult} results`
                  : `${totalResult} result`}{" "}
              </div>
              <Pagination
                totalPage={totalPage}
                onChangePage={handlePageChange}
                forcePage={params.page - 1}
              />
            </div>
          )}
        </div>

        {mode === "new" ? (
          <FormModal
            title={"Add new Shipper "}
            mode={mode}
            updateOpenModal={setOpenModal}
            isOpen={isOpenModal}
            industries={companyIndustries}
            statuses={statuses}
            onSave={handleAddNew}
          />
        ) : (
          <FormModal
            title={"Shipper detail"}
            mode={mode}
            updateOpenModal={setOpenModal}
            isOpen={isOpenModal}
            data={manufactureDetail}
            industries={companyIndustries}
            statuses={statuses}
            onSave={handleUpdateManufacture}
          />
        )}
      </div>
    </div>
  );
};
