export const PATH_MAIN = "/";

//path Admin
export const PATH_ADMIN = "/admin";
export const PATH_ADMIN_AUCTION = "/admin/auction";
export const PATH_ADMIN_DASHBOARD = "/admin/dashboard";
export const PATH_ADMIN_CONTACT_US = "/admin/contact-us";
export const PATH_ADMIN_FORWARDER = "/admin/forwarder";

//PATH Auth
export const PATH_FORGOT_PASSWORD = "/auth/forgot-password";
export const PATH_SIGN_IN = "/auth/sign-in";
export const PATH_VERIFICATION_CODE = "/auth/verification";
export const PATH_RESET_PASSWORD = "/auth/reset-password";
export const PATH_RESET_PASSWORD_SUCCESS = "/auth/reset-password-success";

//path Profile setting
export const PATH_USER_INFO = "/profile/user-info";
export const PATH_CHANGE_PASSWORD = "/profile/password";
