import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputController } from "components/controller/InputController";
import { InputPhoneNumber } from "components/controller/InputPhoneNumber";
import AppSelect from "components/select";
import { UploadController } from "components/upload/UploadController";
import { memo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { forwarderSchema } from "schema/forwarder.schema";
import { STATUS } from "enum";
import styled from "styled-components";
import InputField from "components/fields/InputField";

interface PropsModal {
  title: String;
  isOpen: boolean;
  industries: any[];
  industriesWithArea: any[];
  updateOpenModal: (value: boolean) => void;
  data?: any;
  statuses?: any;
  onSave?: (value: any) => Promise<any>;
  mode: "new" | "detail";
}
const defaultValues = {
  firstName: "",
  lastName: "",
  companyPhoneNumber: "",
  email: "",
  companyName: "",
  companyVatId: "",
  interestedCountry: [] as string[],
  originatedCountryId: { value: "", label: "Select country" },
  status: {
    label: STATUS.active,
    value: STATUS.active,
  },
  companyLogo: [] as string[],
  companyRegistrations: [] as string[],
  additionalDocument: [] as string[],
};

export const FormModal = memo((props: PropsModal) => {
  const {
    isOpen,
    updateOpenModal,
    title,
    data,
    onSave,
    industries,
    industriesWithArea,
    statuses,
    mode,
  } = props;

  const {
    control,
    formState: { errors },
    setValue,
    reset,
    handleSubmit,
    clearErrors,
  } = useForm({
    defaultValues,
    resolver: yupResolver(forwarderSchema(mode)),
    mode: "onChange",
  });

  const handleSave = async (value: any) => {
    try {
      await onSave({ ...data, ...value });
      handleCloseModal();
    } catch (error) {}
  };

  const handleCloseModal = () => {
    clearErrors();
    reset(defaultValues);
    updateOpenModal(false);
  };

  useEffect(() => {
    reset(defaultValues);

    if (data) {
      Object.keys(defaultValues).forEach((key: any) => {
        setValue(key, data?.[key]);
      });
    }
  }, [data]);
  return (
    <FormModalStyle className="form-mdal">
      <Modal isOpen={isOpen} onClose={handleCloseModal} size={"2xl"}>
        <ModalOverlay />
        <ModalContent className="modal-content">
          <ModalHeader className="text-center">{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(handleSave)}>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <InputController
                    control={control}
                    name={"firstName"}
                    placeholder={"Name"}
                    label={"Name"}
                    extra="mb-3"
                    required={true}
                    error={errors.firstName?.message}
                  />

                  <InputPhoneNumber
                    control={control}
                    name={"companyPhoneNumber"}
                    placeholder={"Phone"}
                    label={"Phone"}
                    extra="mb-3"
                    required={true}
                    error={errors.companyPhoneNumber?.message}
                  />

                  <InputController
                    control={control}
                    name={"companyName"}
                    placeholder={"Company name"}
                    label={"Company name"}
                    extra="mb-3"
                    required={true}
                    error={errors.companyName?.message}
                  />
                  {mode === "detail" && (
                    <InputController
                      control={control}
                      name={"companyVatId"}
                      placeholder={"VAT - ID"}
                      label={"VAT - ID"}
                      extra="mb-3"
                      required={true}
                      error={errors.companyVatId?.message}
                    />
                  )}
                  {mode === "detail" && (
                    <AppSelect
                      control={control}
                      name={"status"}
                      placeholder={"Status"}
                      label={"Status"}
                      extra="mb-3"
                      data={statuses}
                      required={true}
                      error={errors.status?.message}
                    ></AppSelect>
                  )}
                  {mode === "detail" && (
                    <AppSelect
                      control={control}
                      label="Originate country"
                      placeholder={"Select country"}
                      name={"originatedCountryId"}
                      extra="mb-3"
                      data={industries}
                      required={true}
                      error={errors.originatedCountryId?.value?.message}
                    ></AppSelect>
                  )}
                  {mode === "detail" && (
                    <InputField
                      id="declinedOffer"
                      value={data?.declined?.toString() ?? "0"}
                      label={"Declined offer"}
                      extra="mb-3"
                      required={true}
                      disabled
                    />
                  )}
                </div>
                <div>
                  <InputController
                    control={control}
                    name={"lastName"}
                    placeholder={"Last name"}
                    label={"Last name"}
                    extra="mb-3"
                    required={true}
                    error={errors.lastName?.message}
                  />
                  {mode === "new" && (
                    <AppSelect
                      control={control}
                      name={"status"}
                      placeholder={"Status"}
                      label={"Status"}
                      extra="mb-3"
                      data={statuses}
                      required={true}
                      error={errors.status?.message}
                    ></AppSelect>
                  )}
                  <InputController
                    control={control}
                    name={"email"}
                    placeholder={"Company email"}
                    label={"Company email"}
                    extra="mb-3"
                    required={true}
                    error={errors.email?.message}
                  />

                  {mode === "detail" && (
                    <AppSelect
                      control={control}
                      label="Country interested"
                      placeholder={"Select industry"}
                      name={"interestedCountry"}
                      extra="mb-3"
                      data={industriesWithArea}
                      required={true}
                      isMulti={true}
                      error={errors.interestedCountry?.message}
                    ></AppSelect>
                  )}

                  {mode === "detail" && (
                    <UploadController
                      control={control}
                      name={"companyLogo"}
                      placeholder={"Company logo"}
                      label={"Company logo"}
                      extra="mb-3"
                      accept={[["image/*", [".jpeg", ".png"]]]}
                      files={data?.companyLogo}
                      required={true}
                      error={errors.companyLogo?.message}
                    />
                  )}

                  {/* <CountriesSelect data={industries} /> */}
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4">
                {mode === "detail" && (
                  <div>
                    <UploadController
                      control={control}
                      name={"companyRegistrations"}
                      placeholder={"Company registration"}
                      accept={[["application/pdf", [".pdf"]]]}
                      desc=".PDF"
                      label={"Company registration"}
                      extra="mb-3"
                      mutilple={true}
                      files={data?.companyRegistrations}
                      required={true}
                      error={errors.companyRegistrations?.message}
                    />
                  </div>
                )}

                {mode === "detail" && (
                  <div>
                    <UploadController
                      control={control}
                      name={"additionalDocument"}
                      placeholder={"Additional document"}
                      label={"Additional document"}
                      extra="mb-3"
                      accept={[["application/pdf", [".pdf"]]]}
                      desc=".PDF"
                      mutilple={true}
                      files={data?.additionalDocument}
                      required={true}
                      error={errors.additionalDocument?.message}
                    />
                  </div>
                )}
              </div>
              <div className="mt-5 flex justify-end">
                <Button colorScheme="blue" mr={3} type="submit">
                  Save
                </Button>
                <Button variant="outline" onClick={handleCloseModal}>
                  Discard
                </Button>
              </div>
            </form>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </FormModalStyle>
  );
});
const FormModalStyle = styled.div`
  .modal-content {
    max-height: 90vh;
  }
`;
