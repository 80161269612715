import { SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import Card from "components/card";

const Widget = (props: {
  icon: JSX.Element;
  title: string;
  subtitle: string;
  children?: string | React.ReactElement;
  isLoaded?: boolean;
}) => {
  const { icon, title, subtitle, children, isLoaded = true } = props;
  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px] p-3">
      <SkeletonCircle size="10" isLoaded={isLoaded}>
        <div className="flex flex-row items-center">
          <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
            <span className="flex items-center text-brand-500 dark:text-white">
              {icon}
            </span>
          </div>
        </div>
      </SkeletonCircle>

      <SkeletonText
        mt="4"
        noOfLines={4}
        spacing="4"
        skeletonHeight="2"
        isLoaded={isLoaded}
      >
        <div className="ml-4 flex w-auto flex-col justify-center">
          <p className="font-dm font-medium text-gray-600 ">{title}</p>
          <h4 className="py-1 text-2xl font-bold text-navy-700 dark:text-white">
            {subtitle}
          </h4>
          <div className="mt-1 font-dm font-medium text-gray-600">
            {children}
          </div>
        </div>
      </SkeletonText>
    </Card>
  );
};

export default Widget;
