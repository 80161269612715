import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Spinner } from "@chakra-ui/react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  data?: string;
};

export const PreviewInvoiceModal = (props: Props) => {
  const { isOpen, onClose, data } = props;

  const handleCloseModal = () => {
    onClose();
  };
  return (
    <Modal isCentered isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent className="h-[100vh] min-w-[100vw] items-center overflow-auto !bg-[transparent]">
        <ModalCloseButton className="!h-10 !w-10 !rounded-full !bg-white" />
        {/* reder iframe thar show a pdf base 64 */}
        {data ? (
          <iframe
            title="Invoice detail"
            src={`data:application/pdf;base64,${data}`}
            className="h-full w-[75%] "
          />
        ) : (
          <Spinner />
        )}
      </ModalContent>
    </Modal>
  );
};
