import { API_ENDPOINT } from "enum";
import { AxiosService } from "./axiosService";

export const dashboardAPI = {
  getStatistic: (params: any) => {
    const url = API_ENDPOINT.DASHBOARD_STATISTIC;
    return AxiosService.get(url, { params });
  },
  getStatisticDestination: (params: any) => {
    const url = API_ENDPOINT.DASHBOARD_STATISTIC_DESTINATION;
    return AxiosService.get(url, { params });
  },
  getListCountry: () => {
    return AxiosService.get(API_ENDPOINT.COUNTRY);
  },
};
