import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/table";
import { Spinner } from "@chakra-ui/react";
import { ITableColumn } from "interfaces";
import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { AppIcon } from "components/icons";
import { ESortType } from "config";

type Props<T extends Record<string, any> = Record<string, any>> = {
  columns: ITableColumn<T>[];
  data: T[];
  caption?: string;
  variant?: "simple" | "striped" | "unstyled";
  showFooter?: boolean;
  loading?: boolean;
  onDoubleClick?: (value: T) => void;
  onSort?: (sort: ISorter<T>) => void;
  minRows?: number;
};

type TSort = keyof typeof ESortType | null;

export interface ISorter<T> {
  key: keyof T | null;
  value: TSort;
}

export const TableLayout = React.memo(
  <T extends Record<string, any> = Record<string, any>>(props: Props) => {
    const {
      columns,
      data,
      caption = "",
      variant = "simple",
      showFooter = false,
      loading,
      onDoubleClick,
      onSort,
      minRows,
    } = props;

    const [sorter, setSorter] = useState<ISorter<T>>({
      key: null,
      value: null,
    });

    const dataFitting = useMemo(() => {
      if (minRows && data.length < minRows && data.length > 0) {
        const emptyRows = Array(minRows - data.length).fill({});
        return [...emptyRows];
      }
      return [];
    }, [data, minRows]);

    const renderDefault = (value: any) => {
      return <p>{value ?? "_"}</p>;
    };

    const handleSort = (col: ITableColumn<Record<string, any>>) => {
      setSorter((prev: ISorter<T>) => {
        if (prev.key === col?.key) {
          let value: TSort = null;
          switch (prev.value) {
            case ESortType.ASC:
              value = ESortType.DESC;
              break;
            case ESortType.DESC:
              value = null;
              break;
            default:
              value = ESortType.ASC;
              break;
          }
          return {
            key: col?.key || null,
            value,
          };
        } else {
          return {
            key: col?.key || null,
            value: ESortType.ASC,
          };
        }
      });
    };

    useEffect(() => {
      onSort?.(sorter as ISorter<Record<string, any>>);
    }, [sorter]);

    const Header = () => {
      return (
        <Thead>
          <Tr>
            {columns.map((col, index) => (
              <Th className="bg-gray-200" key={index + col.key}>
                <div className="flex">
                  {col.renderTitle ? col.renderTitle() : col.title || ""}
                  {col?.isSortable && (
                    <div
                      className="ml-2 cursor-pointer"
                      onClick={() => handleSort(col)}
                    >
                      <AppIcon
                        name="sortUp"
                        size={8}
                        fill={
                          sorter.key === col.key &&
                          sorter.value === ESortType.ASC
                            ? "red"
                            : ""
                        }
                      />
                      <AppIcon
                        name="sortDown"
                        size={8}
                        fill={
                          sorter.key === col.key &&
                          sorter.value === ESortType.DESC
                            ? "red"
                            : ""
                        }
                      />
                    </div>
                  )}
                </div>
              </Th>
            ))}
          </Tr>
        </Thead>
      );
    };

    const Body = () => {
      return (
        <Tbody>
          {data.map((value, index) => {
            return (
              <Tr
                key={value?.id || index}
                onDoubleClick={() => onDoubleClick?.(value)}
                className="even:bg-slate-50 odd:bg-white  "
              >
                {columns.map((col, indexCol) => {
                  return (
                    <Td key={indexCol + col.key}>
                      {col.render
                        ? col.render(value[col.key], { ...value, index })
                        : renderDefault(value[col.key])}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
          {dataFitting.map((_, index) => (
            <Tr key={index}>
              {columns.map((col, indexCol) => (
                <Td key={indexCol + col.key}>
                  <p></p>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      );
    };

    const Footer = () => {
      return (
        <Tfoot>
          <Tr>
            {columns.map((col, index) => (
              <Th key={index + col.key}>
                {col.renderTitle ? col.renderTitle() : col.title || ""}
              </Th>
            ))}
          </Tr>
        </Tfoot>
      );
    };

    return (
      <Wrapper $allowDbClick={!!onDoubleClick}>
        <TableContainer>
          <Table variant={variant}>
            {caption && <TableCaption>{caption}</TableCaption>}
            <Header />
            {!loading && <Body />}
            {showFooter && <Footer />}
          </Table>
          {loading ? (
            <div className="loading">
              <Spinner />
            </div>
          ) : (
            !data?.length && (
              <div className="bg-white py-8 text-center">No data</div>
            )
          )}
        </TableContainer>
      </Wrapper>
    );
  }
) as <T extends Record<string, any> = Record<string, any>>(
  props: Props<T>
) => JSX.Element;
const Wrapper = styled.div<{
  $allowDbClick: boolean;
}>`
  position: relative;
  min-height: 95px;
  tbody tr {
    ${(p) =>
      p?.$allowDbClick &&
      css`
        cursor: pointer;
      `}
  }
  tr {
    height: 53px;
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    opacity: 0.5;
    height: 6rem;
  }

  tbody tr:hover {
    background-color: rgb(219 234 254);
  }
`;
