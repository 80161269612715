import styled, { useTheme } from "styled-components";

import { AppIcon } from "components/icons";
import { TIconMap } from "components/icons/AppIcon";
import { AppImage } from "image";
import { Link } from "react-router-dom";

type Props = {
  item: any;
  index: number;
  iconDelete?: TIconMap;
  onDelete?: (item: any, index: number) => void;
  status?: any;
  type?: "image" | "document";
  previewUrl?: string;
  replaceEnable?: boolean;
};

export const ItemUpload = (props: Props) => {
  const {
    iconDelete = "close",
    index,
    item,
    onDelete,
    type = "image",
    previewUrl,
    replaceEnable,
  } = props;
  const theme = useTheme();

  return (
    <Link target="_blank" to={item?.url ?? ""}>
      <StyledItemUpload className="item__upload">
        {type === "document" && (
          <AppIcon size={40} name="file" className="pdf-icon" />
        )}
        {type === "image" && (
          <AppImage
            src={previewUrl}
            size={32}
            containerClassName="image-preview"
            shape="circle"
          />
        )}
        <div className="item__content ellipsis w-12">{item?.title}</div>
        {!replaceEnable && (
          <AppIcon
            className="item__close--icon"
            name={iconDelete}
            onClick={(e) => {
              e.preventDefault()
              onDelete && onDelete(item, index);
            }}
            size={24}
            color={theme.colors.neutral500}
          />
        )}
      </StyledItemUpload>
    </Link>
  );
};

export const StyledItemUpload = styled.div<{
  status?: string;
}>`
  width: 100%;
  height: 7.2rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background: ${(props) => props.theme.colors.neutral100};
  border-radius: 1.2rem;
  margin-top: 0.5rem;
  gap: 1.6rem;

  .item__close--icon {
    flex-shrink: 0;
    cursor: pointer;
  }

  .pdf-icon,
  .image-preview {
    flex-shrink: 0;
  }

  .item__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .item__title {
      font-size: 1.8rem;
      /* text-align: center; */
      overflow-wrap: anywhere;
      font-style: normal;
      font-weight: 500;
      line-height: 2.8rem;
      width: fit-content;
      color: ${(props) => props.theme.colors.neutral900};
    }
    .item__des {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 1rem;
    }
    .item__des--text {
      font-size: 1.8rem;
      /* text-align: center; */
      overflow-wrap: anywhere;
      font-style: normal;
      font-weight: 500;
      line-height: 2.8rem;
      width: fit-content;
      color: ${(props) => props.theme.colors.neutral500};
      margin-right: 0.6rem;
    }
    .item__des--status {
      font-size: 1.6rem;
      /* text-align: center; */
      overflow-wrap: anywhere;
      font-style: normal;
      font-weight: 400;
      line-height: 2.4rem;
      width: fit-content;
      color: ${(props) => props.theme.colors.neutral900};
    }
  }
`;
