import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useCommon = () => {
  const AppDispatch = useDispatch();
  const AppNavigate = useNavigate();
  // const AppSelector = (callback: (state: RootState) => any) => {
  //   return useSelector(callback);
  // };

  return {
    AppDispatch,
    AppNavigate,
    // AppSelector,
    useSelector
  };
};
