import { useNavigate } from "react-router-dom";

import { Button } from "components/button/Button";
import { PATH_SIGN_IN } from "navigation";

export default function ResetPasswordSuccess() {
  const navigate = useNavigate();

  const onSubmit = () => {
    navigate(PATH_SIGN_IN);
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10">
      {/* Sign in section */}
      <div className="mt-[10vh] min-w-[30vw]  flex-col items-center md:max-w-[420px] md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-center text-4xl font-bold text-navy-700 dark:text-white">
          Password changed!
        </h4>
        <p className="mb-9 ml-1 text-center text-base text-gray-600">
          You've Successfully Completed Your Password Reset
        </p>

        <Button title="Log In Now" onClick={onSubmit} />
      </div>
    </div>
  );
}
