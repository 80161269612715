import { API_ENDPOINT } from "enum";
import { AxiosService } from "./axiosService";

interface ILoginData {
  accessToken: string;
  expires: number;
  refreshToken: string;
}

export const authAPI = {
  login: (body: any = {}) => {
    const url = API_ENDPOINT.SIGN_IN;
    return AxiosService.post<ILoginData>(url, body);
  },
  signUp: (body?: any) => {
    const url = API_ENDPOINT.SIGN_UP;
    return AxiosService.post(url, body);
  },
  // forgotPassword: (lang: string, body?: any) => {
  //   return AxiosService.post(url, body, {
  //     headers: {
  //       lang,
  //     },
  //   })
  // },
  forgotPassword: (body?: any) => {
    const url = API_ENDPOINT.FORGOT_PASSWORD;
    return AxiosService.post(url, body);
  },
  sendOtp: (body: any) => {
    const url = API_ENDPOINT.SEND_OTP;
    return AxiosService.post<{ hash: string }>(url, body);
  },
  verifyOtp: (body: any) => {
    const url = API_ENDPOINT.VERIFY_OTP;
    return AxiosService.post<{ hash: string }>(url, body);
  },
  changePassword: (body?: any) => {
    const url = API_ENDPOINT.CHANGE_PASSWORD;
    return AxiosService.patch(url, body);
  },
  resetPassword: (body?: any) => {
    const url = API_ENDPOINT.RESET_PASSWORD;
    return AxiosService.post(url, body);
  },
  logout: (body?: any) => {
    const url = API_ENDPOINT.LOGOUT;
    return AxiosService.post(url, body);
  },
};
