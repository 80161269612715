import * as yup from "yup";

import { requireTextSchema, phoneNumberSchema } from "./schema";

export const profileSchema = yup.object().shape({
  firstName: requireTextSchema("This field is required"),
  lastName: requireTextSchema("This field is required"),
  companyPhoneNumber: phoneNumberSchema(),
  email: requireTextSchema("This field is required"),
});
