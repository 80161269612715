import dayjs from "dayjs";

//function that return the start of last year
export const startOfLastYear = () => {
  return dayjs().subtract(1, "year").startOf("year").toDate();
};

//function that return the end of last year
export const endOfLastYear = () => {
  return dayjs().subtract(1, "year").endOf("year").toDate();
};

//function that return the start of last 2 years
export const startOfLast2Years = () => {
  return dayjs().subtract(2, "year").startOf("year").toDate();
};

//function that return the end of last 2 years
export const endOfLast2Years = () => {
  return dayjs().subtract(2, "year").endOf("year").toDate();
};

//function that return the start of last month
export const startOfLastMonth = () => {
  return dayjs().subtract(1, "month").startOf("month").toDate();
};

//function that return the end of last month
export const endOfLastMonth = () => {
  return dayjs().subtract(1, "month").endOf("month").toDate();
};
