import { Controller } from "react-hook-form";
import { ReactSelect } from "./ReactSelect";

const AppSelect = (props: any) => {
  const {
    placeholder,
    onChange,
    data,
    control,
    name,
    rules,
    defaultValue = "",
    disabledController = false,
    error,
    label,
    required = false,
    isMulti = false,
    ...restProps
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      disabled={disabledController}
      // shouldUnregister={shouldUnregister}
      render={({ field }) => {
        return (
          <div className="mb-3">
            <label
              className={`flex text-sm font-bold font-medium text-navy-700 dark:text-white`}
            >
              {label}
              {required && <p className="text-red-600">*</p>}
            </label>

            <ReactSelect
              value={field.value}
              onChange={field.onChange}
              isMulti={isMulti}
              options={data}
              placeholder={placeholder}
              {...restProps}
            />
            {error && (
              <p className="mt-1 text-xs text-red-500 dark:text-red-400">
                {error}
              </p>
            )}
          </div>
        );
      }}
    />
  );
};

export default AppSelect;
