export const StyledTheme = {
  colors: {
    white: "#FFFFFF",
    lightBlue50: "#E9F6FC",
    lightBlue100: "#DEF1FA",
    lightBlue200: "#BCE2F4",
    lightBlue300: "#26A2DD",
    lightBlue400: "#2292C7",
    lightblue500: "#1E82B1",
    lightBlue600: "#1D7AA6",
    lightBlue700: "#176185",
    lightBlue800: "#114963",
    lightBlue900: "#0D394D",
    darkBlue50: "#E6E6EC",
    darkBlue100: "#DADAE3",
    darkBlue200: "#B2B2C5",
    darkBlue300: "#060544",
    darkBlue400: "#05053D",
    darkBlue500: "#050436",
    darkBlue600: "#050433",
    darkBlue700: "#040329",
    darkBlue800: "#03021F",
    darkBlue900: "#020218",
    pink50: "#FEE7F1",
    pink100: "#FEDBEA",
    pink200: "#FCB4D4",
    pink300: "#F60C75",
    pink400: "#DD0B69",
    pink500: "#C50A5E",
    pink600: "#B90958",
    pink700: "#940746",
    pink800: "#6F0535",
    pink900: "#560429",
    green50: "#EBFEFA",
    green100: "#E1FDF8",
    green200: "#C1FBF0",
    green300: "#36F1CD",
    green400: "#31D9B9",
    green500: "#2BC1A4",
    green600: "#29B59A",
    green700: "#20917B",
    green800: "#186C5C",
    green900: "#135448",
    error25: "#FFFBFA",
    error50: "#FEF3F2",
    error100: "#FEE4E2",
    error200: "#FECDCA",
    error300: "#FDA29B",
    error400: "#F97066",
    error500: "#F04438",
    error600: "#D92D20",
    error700: "#B42318",
    error800: "#912018",
    error900: "#7A271A",
    warning25: "#FFFCF5",
    warning50: "#FFFAEB",
    warning100: "#FEF0C7",
    warning200: "#FEDF89",
    warning300: "#FEC84B",
    warning400: "#FDB022",
    warning500: "#F79009",
    warning600: "#DC6803",
    warning700: "#B54708",
    warning800: "#93370D",
    warning900: "#7A2E0E",
    success25: "#F6FEF9",
    success50: "#ECFDF3",
    success100: "#D1FADF",
    success200: "#A6F4C5",
    success300: "#6CE9A6",
    success400: "#32D583",
    success500: "#12B76A",
    success600: "#039855",
    success700: "#027A48",
    success800: "#05603A",
    success900: "#054F31",
    neutral25: "#FCFCFD",
    neutral50: "#F9FAFB",
    neutral100: "#F2F4F7",
    neutral200: "#EAECF0",
    neutral300: "#D0D5DD",
    neutral400: "#98A2B3",
    neutral500: "#667085",
    neutral600: "#475467",
    neutral700: "#344054",
    neutral800: "#1D2939",
    neutral900: "#101828",
    gray100: "#F9F9F9",
    gray200: "#F4F4F4",
    gray250: "#eef0f3",
    gray300: "#E1E3EA",
    gray400: "#B5B5C3",
    gray500: "#A1A5B7",
    gray600: "#7E8299",
    gray700: "#5E6278",
    gray800: "#3F4254",
    gray900: "#181C32",
    creamDark: "#EFE2C1",
    creamLight: "#FCF9F3",
    redErr: "#E14942",
    red: "#D56544",
    redStatus: "#EB5757",
    redLight: "#F7E0DA",
    redDark: "#8E3115",
    redSuperDark: "#6C0C0C",
    cream: "#F7F0DE",
    yellow: "#F6BD60",
    yellowLight: "#FDF2DF",
    yellowDark: "#DD9A2D",
    fontDark: "#4D4A42",
    fontLight: "#B5AD99",
    greenDark: "#37B679",
    greenLight: "#DCF9EB",
    purpleLight: "#D60067",
    grayDark: "#d9d8d7",
    bluePrimary: "#4B64F5",
  },
  boxShadow: {
    shadowSm:
      "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
    shadowLg:
      "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
  },
  fonts: {
    Poppins: "Poppins, sans-serif",
  },
};
