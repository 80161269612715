import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputController } from "components/controller/InputController";
import { InputPhoneNumber } from "components/controller/InputPhoneNumber";
import AppSelect from "components/select";
import { UploadController } from "components/upload/UploadController";
import { STATUS } from "enum";
import { memo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { manufactureSchema } from "schema";

interface PropsModal {
  title: String;
  isOpen: boolean;
  industries: any[];
  updateOpenModal: (value: boolean) => void;
  data?: any;
  mode: "new" | "detail";
  statuses?: any;
  onSave?: (value: any) => Promise<any>;
}
const defaultValues = {
  firstName: "",
  lastName: "",
  companyPhoneNumber: "",
  email: "",
  companyName: "",
  companyVatId: "",
  // companyAddress: "",
  companyIndustryId: { value: "", label: "Select company" },
  companyIndustryOther: "",
  status: {
    label: STATUS.active,
    value: STATUS.active,
  },
  companyLogo: [] as string[],
  companyRegistrations: [] as string[],
};

export const FormModal = memo((props: PropsModal) => {
  const {
    isOpen,
    updateOpenModal,
    title,
    data,
    onSave,
    industries,
    statuses,
    mode,
  } = props;

  const {
    control,
    formState: { errors },
    setValue,
    reset,
    watch,
    handleSubmit,
    clearErrors,
  } = useForm({
    defaultValues,
    resolver: yupResolver(manufactureSchema(mode)),
    mode: "onChange",
  });

  const watchCompanyIndustry = watch("companyIndustryId"); // when pass nothing as argument, you are watching everything

  const handleSave = async (value: any) => {
    try {
      await onSave({ ...data, ...value });
      handleCloseModal();
    } catch (error) {}
  };

  const handleCloseModal = () => {
    clearErrors();
    reset(defaultValues);
    updateOpenModal(false);
  };

  useEffect(() => {
    reset(defaultValues);
    if (data) {
      Object.keys(defaultValues).forEach((key: any) => {
        setValue(key, data?.[key]);
      });
    }
  }, [data]);
  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} size={"2xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="text-center">{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(handleSave)}>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <InputController
                  control={control}
                  name={"firstName"}
                  placeholder={"Name"}
                  label={"Name"}
                  extra="mb-3"
                  required={true}
                  error={errors.firstName?.message}
                />
                <InputPhoneNumber
                  control={control}
                  name={"companyPhoneNumber"}
                  placeholder={"Company phone"}
                  label={"Company phone"}
                  extra="mb-3"
                  required={true}
                  error={errors.companyPhoneNumber?.message}
                />

                <InputController
                  control={control}
                  name={"companyName"}
                  placeholder={"Company name"}
                  label={"Company name"}
                  extra="mb-3"
                  required={true}
                  error={errors.companyName?.message}
                />
                {mode === "detail" && (
                  <AppSelect
                    control={control}
                    label="Company Industry"
                    placeholder={"Select industry"}
                    name={"companyIndustryId"}
                    extra="mb-3"
                    data={industries}
                    required={true}
                    error={errors.companyIndustryId?.value?.message}
                  ></AppSelect>
                )}
                {watchCompanyIndustry?.label === "Others" && (
                  <InputController
                    control={control}
                    name={"companyIndustryOther"}
                    placeholder={"Description"}
                    label={"Description"}
                    extra="mb-3"
                    required={true}
                    error={errors.companyIndustryOther?.message}
                  />
                )}
                {mode === "detail" && (
                  <UploadController
                    control={control}
                    name={"companyRegistrations"}
                    placeholder={"Company registration"}
                    label={"Company registration"}
                    extra="mb-3"
                    accept={[["application/pdf", [".pdf"]]]}
                    desc=".PDF"
                    mutilple={true}
                    files={data?.companyRegistrations}
                    required={true}
                    error={errors.companyRegistrations?.message}
                  />
                )}
              </div>
              <div>
                <InputController
                  control={control}
                  name={"lastName"}
                  placeholder={"Last name"}
                  label={"Last name"}
                  extra="mb-3"
                  required={true}
                  error={errors.lastName?.message}
                />
                <InputController
                  control={control}
                  name={"email"}
                  placeholder={"Company email"}
                  label={"Company email"}
                  extra="mb-3"
                  required={true}
                  error={errors.email?.message}
                />
                {mode === "detail" && (
                  <InputController
                    control={control}
                    name={"companyVatId"}
                    placeholder={"VAT - ID"}
                    label={"VAT - ID"}
                    extra="mb-3"
                    required={true}
                    error={errors.companyVatId?.message}
                  />
                )}
                <AppSelect
                  control={control}
                  name={"status"}
                  placeholder={"Status"}
                  label={"Status"}
                  extra="mb-3"
                  data={statuses}
                  required={true}
                  error={errors.status?.message}
                ></AppSelect>
                {mode === "detail" && (
                  <UploadController
                    control={control}
                    name={"companyLogo"}
                    placeholder={"Company logo"}
                    desc=".PNG, .JPG"
                    accept={[["image/*", [".jpeg", ".png"]]]}
                    label={"Company logo"}
                    extra="mb-3"
                    files={data?.companyLogo}
                    required={true}
                    error={errors.companyLogo?.message}
                  />
                )}
              </div>
            </div>
            <div className="mt-5 flex justify-end">
              <Button colorScheme="blue" mr={3} type="submit">
                Save
              </Button>
              <Button variant="outline" onClick={handleCloseModal}>
                Discard
              </Button>
            </div>
          </form>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
});
