import React, {
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Dropzone from "react-dropzone";
import styled, { css } from "styled-components";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { LogApp, showAppToast } from "utils";
import { AppIcon } from "components/icons";
// import { AppImage } from "../image";
import { AppSpin } from "components/loading/AppSpin";
import { ItemUpload } from "./ItemUpload";
import { uploadAPI } from "api";
import { convertAceptFile } from "utils";
import { BREAK_POINT } from "config";

interface DropProps {
  desc?: any;
  title?: any;
  preview?: boolean;
  accept?: any;
  maxSize?: number;
  previewUrl?: string;
  onUploadStart?: () => void;
  onUploadEnd?: (data: any) => void;
  onRemove?: () => void;
  isDisabled?: boolean;
  loading?: boolean;
  mutilple?: boolean;
  limit?: number;
  onChangeListFile?: (data: any[]) => void;
  files?: any[];
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  uploadType?: "image" | "document";
  label: string;
  required?: boolean;
  replaceEnable?: boolean;
}

const LIMIT_FILE = 5;

export const AppRemoteUpload = React.forwardRef<any, DropProps>(
  (props, appRemoteUploadRef) => {
    const {
      desc = "PNG, JPG",
      preview = true,
      maxSize = 10485760,
      accept,
      previewUrl,
      onUploadStart,
      onUploadEnd,
      onRemove,
      isDisabled,
      mutilple = false,
      title = "Upload file",
      limit = LIMIT_FILE,
      onChangeListFile,
      files,
      error,
      uploadType = "image",
      label,
      required = false,
      replaceEnable = false,
    } = props;

    const [imageUrl, setImageUrl] = useState<string | undefined>(previewUrl);
    const [isloading, setIsLoading] = useState<boolean>(false);
    const [listFile, setListFile] = useState<any[]>(files || []);
    const dropzoneRef = useRef<any>(null);
    const dropzoneInputRef = useRef<any>(null);
    const mimeTypeAccept = useMemo(() => convertAceptFile(accept), [accept]);

    const sizeByteToMB = (b: number): number => Math.round(b / 1048576);

    // const onUploadFail = (file: any) => {
    //   //delete File
    // };

    const updateListFile = (file: any) => {
      if (mutilple) {
        setListFile([...listFile, file]);
        onChangeListFile && onChangeListFile([...listFile, file]);
      } else {
        setListFile([file]);
        onChangeListFile && onChangeListFile([file]);
      }
    };

    const handleUpload = async (file: File) => {
      try {
        LogApp("Upload file", file);
        onUploadStart && onUploadStart();
        setIsLoading(true);
        const formData = new FormData();
        formData.append("file", file);

        const res = await uploadAPI.uploadFile(formData);
        console.log("RES UPLOAD", res);

        if (res.data) {
          onUploadEnd && onUploadEnd(res?.data);
          updateListFile(res?.data);
        }
      } catch (error: any) {
        LogApp("Upload error", error);
        showAppToast("Upload fail", "error", "colored", "loginError");
        // onUploadFail(file);
      } finally {
        setIsLoading(false);
      }
    };

    const uploadFile = async (file: File) => {
      handleUpload(file);
    };

    // const uploadMutilpleFile = async (files: File[]) => {
    //   if (!onUploadEnd) return;
    //   const promises = files.map(file => handleUpload(file));
    //   await Promise.all(promises);
    // };

    const onDrop = async (files: File[]) => {
      if (isDisabled) return;
      LogApp("Drop files", files);
      if (files === undefined || files.length === 0) {
        showAppToast(`File not found`, "warning");
        return;
      }
      if (files[0]?.size > maxSize) {
        showAppToast(
          `${"Max file size must be less"} ${sizeByteToMB(maxSize)}MB`,
          "warning"
        );
        return;
      }
      if (mutilple && files.length + listFile.length > limit) {
        showAppToast(`You can only upload max of ${limit} files`, "warning");
        return;
      }
      // else if (mutilple) uploadMutilpleFile(files);
      else uploadFile(files[0]);
    };

    const dragOver = (e: any) => {
      e.preventDefault();
    };

    const dragEnter = (e: any) => {
      e.preventDefault();
    };

    const dragLeave = (e: any) => {
      e.preventDefault();
    };

    // const dropRejected = (fileRejections: any, e: any) => {
    //   e.preventDefault();
    // };

    const openDialog = () => {
      if (dropzoneRef?.current) {
        dropzoneRef?.current?.open();
      }
    };

    const removeCurrentFile = () => {
      setImageUrl(undefined);
      onRemove && onRemove();
    };

    const isDisabledUpload = () => {
      if (isDisabled) return true;
      if (mutilple) return listFile.length >= limit;
      return !!listFile.length && !replaceEnable;
    };

    const onClickDelete = (_: any, index: number) => {
      const newListFile = listFile.filter((_, i) => i !== index);
      setListFile(newListFile);
      onChangeListFile && onChangeListFile(newListFile);
    };

    const getAcceptFileType = () => {
      if (!accept) return "";
      let fileType = "";
      accept.forEach((item: any, index: any) => {
        const type = item[0].split("/")[1].toUpperCase();
        fileType = fileType.concat(
          `${
            index === 0
              ? `${type}`
              : index === accept.length - 1
              ? ` ${"upload.or"} ${type} `
              : `, ${type}`
          }`
        );
      });
      return fileType;
    };

    const reset = () => {
      setListFile([]);
    };

    const setFiles = (files: any[]) => {
      setListFile(files);
    };

    useImperativeHandle(
      appRemoteUploadRef,
      () => {
        return {
          reset,
          setFiles,
        };
      },
      []
    );

    useLayoutEffect(() => {
      setListFile(files || []);
    }, [files]);

    useEffect(() => {
      setImageUrl(previewUrl);
    }, [previewUrl]);

    return (
      <>
        <Wrapper
          $mutilple={mutilple}
          className="app-upload"
          $isDisabled={isDisabledUpload()}
        >
          {label && (
            <label
              className={`ml-1.5 flex text-sm font-medium text-navy-700 dark:text-white`}
            >
              {label}
              {required && <p className="text-red-600">*</p>}
            </label>
          )}
          {!isloading && (
            <Dropzone
              ref={dropzoneRef}
              onDrop={onDrop}
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              multiple={false}
              accept={mimeTypeAccept}
            >
              {({ getRootProps, getInputProps }: any) => (
                <section className="container" style={{ width: "100%" }}>
                  <UploadBox
                    {...getRootProps({ className: "dropzone" })}
                    className="upload-box"
                    $hidden={!!imageUrl && preview}
                  >
                    <input ref={dropzoneInputRef} {...getInputProps()} />
                    <div className="upload-box__inner">
                      <span className="upload-icon">
                        <AppIcon name="upload" />
                      </span>
                      <div className="upload-des__container">
                        {title}
                        <br />
                        {desc
                          .replace("$accept", getAcceptFileType())
                          .replace(
                            "$size",
                            `${sizeByteToMB(maxSize).toString()}MB`
                          )}
                      </div>
                    </div>
                  </UploadBox>

                  <PreviewWrapper
                    $hidden={!imageUrl || !preview}
                    className="app-upload-preview"
                  >
                    {/* {!!imageUrl && <AppImage src={imageUrl} alt="upload-img" />} */}
                    {!!imageUrl}
                    {!isDisabled && (
                      <div className="upi-actions">
                        <div
                          onClick={removeCurrentFile}
                          className="remove-image icon-btn"
                        >
                          <AppIcon name="close" />
                        </div>
                        <div
                          onClick={openDialog}
                          className="upload-new icon-btn"
                        >
                          <AppIcon name="close" />
                        </div>
                      </div>
                    )}
                  </PreviewWrapper>
                </section>
              )}
            </Dropzone>
          )}
          {isloading && (
            <LoadingWrapper className="app-upload-loading-container">
              <AppSpin />
            </LoadingWrapper>
          )}
          {error && (
            <p className="mt-1 text-xs text-red-500 dark:text-red-400">
              {String(error)}
            </p>
          )}
        </Wrapper>
        {listFile.length > 0 && (
          <ListUploadFile className="list-upload-file">
            {listFile.map((item, index) => {
              return (
                <ItemUpload
                  item={item}
                  index={index}
                  key={`${item?.title}${index}`}
                  // iconDelete={mutilple ? 'close' : 'trash'}
                  onDelete={onClickDelete}
                  type={uploadType}
                  previewUrl={item?.url}
                  replaceEnable={replaceEnable}
                />
              );
            })}
          </ListUploadFile>
        )}
      </>
    );
  }
);

AppRemoteUpload.displayName = "AppRemoteUpload";

const Wrapper = styled.div<{
  $isDisabled?: boolean;
  $mutilple?: boolean;
}>`
  width: 100%;
  height: ${(props) =>
    props.$mutilple || props.$isDisabled ? "8.6rem" : "8rem"};
  position: relative;
  margin-bottom: 3.5rem;
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      user-select: none;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      pointer-events: none;
      opacity: 0.5;
    `}
  .container {
    width: 100%;
    height: 100%;
    position: relative;
    .upload-box {
      border: 1px dashed ${(props) => props.theme.colors.neutral500};
      cursor: pointer;
      border-radius: 10px;
      aspect-ratio: 1;
      width: 100%;
      height: 100%;
      padding: 10px 5px;
      .upload-box__inner {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0 1.2rem;
        flex-direction: ${(props) =>
          props.$mutilple || props.$isDisabled ? "row" : "column"};
        justify-content: center;
        align-items: center;

        .upload-des__container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          ${(props) =>
            props.$mutilple || props.$isDisabled
              ? css`
                  flex-grow: 1;
                  align-items: left;
                  margin: 0.5rem;
                `
              : css`
                  margin: 0.5rem;
                  align-items: center;
                `};
          .upload-desc {
            font-size: 1.2rem;
            text-align: left;
            font-style: normal;
            font-weight: 400;
            line-height: 1.8rem;
            color: ${(props) => props.theme.colors.neutral500};
            margin-top: 1.2rem;
          }

          .upload-title {
            font-size: 1.6rem;
            text-align: left;
            font-style: normal;
            font-weight: 500;
            line-height: 2.4rem;
            color: ${(props) => props.theme.colors.neutral900};
          }
        }

        .button-upload {
          background-color: ${(props) => props.theme.colors.white};
          border-radius: 0.6rem;
          height: 3.6rem;
          padding: 0 2.4rem;
          width: fit-content;
          margin-top: ${(props) =>
            props.$mutilple || props.$isDisabled ? "0rem" : "2.4rem"};
          border: 0.1rem solid ${(props) => props.theme.colors.lightBlue300};
          .button-upload__text {
            font-size: 1.4rem;
            text-align: center;
            font-style: normal;
            font-weight: 500;
            line-height: 2rem;
            color: ${(props) => props.theme.colors.lightBlue300};
            margin-left: 0.8rem;
          }
        }

        .upload-icon {
          svg {
            width: 48px;
            height: 48px;
          }
        }
      }
    }
  }

  .input-text-error {
    margin-top: 0.5rem;
    margin-bottom: 0;
    color: ${(p) => p?.theme?.colors?.error600};
    font-size: 1.2rem;
    position: relative;

    @media (max-width: ${BREAK_POINT.mobile}) {
      font-size: 1.1rem;
    }
  }
`;

const UploadBox = styled.div<{
  $hidden?: boolean;
}>`
  ${({ $hidden }) =>
    $hidden &&
    css`
      position: absolute;
      opacity: 0;
      width: 0 !important;
      height: 0 !important;
      visibility: hidden;
    `}
`;

const ListUploadFile = styled.section`
  width: 100%;
  .upload__text {
    font-size: 1.8rem;
    text-align: left;
    font-style: normal;
    font-weight: 600;
    line-height: 2.8rem;
    color: ${(props) => props.theme.colors.darkBlue300};
    margin-top: 2.4rem;
  }
`;

const PreviewWrapper = styled.div<{
  $hidden?: boolean;
}>`
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  .upi-actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    padding: 0 10px 10px;
    z-index: 3;
    .icon-btn {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 1px solid ${(props) => props.theme.colors.cream};
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: ${(props) => props.theme.colors.cream};
        }
      }
      &:hover {
        opacity: 0.86;
      }
    }
  }
  &:hover {
    .upload-img {
      filter: brightness(60%);
    }
  }
  ${({ $hidden }) =>
    $hidden &&
    css`
      position: absolute;
      opacity: 0;
      width: 0 !important;
      height: 0 !important;
      visibility: hidden;
    `}
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${(props) => props.theme.colors.fontLight};
  border-radius: 10px;
  background-color: inherit;
`;
