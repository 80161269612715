// Import the functions you need from the SDKs you need
// import { notificationAPI } from "api/notificationAPI";
import { notificationAPI } from "api/notificationAPI";
import { initializeApp } from "firebase/app";

import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { setNewNotification, store } from "store";
import { LogApp } from "utils";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  validKey: process.env.REACT_APP_FIREBASE_VALID_KEY,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

const initToken = () => {
  getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VALID_KEY,
  }).then(async (currentToken) => {
    if (currentToken) {
      const userAgent = window.navigator.userAgent;

      try {
        notificationAPI.registerFirebaseToken({
          token: `${currentToken}`,
          deviceId: `${userAgent}`,
          deviceType: "WEB_PUSH",
        });
      } catch (error) {
        LogApp("Error registering token. ", error);
      }
      onMessage(messaging, (data) => {
        LogApp("Message received. ", data);
        store.dispatch(setNewNotification(data?.data));
      });
    }
  });
};

export const requestPermission = () => {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      initToken();
    }
  });
};
