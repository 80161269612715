import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import { authPersistConfig, authSlice } from "./slices/auth";
import { appPersistConfig, appSlice } from "./slices/app";
import { auctionPersistConfig } from "./slices/auction";
import auctionSlice from "./slices/auction/auctionSlice";
import { notificationPersistConfig, notificationSlice } from "./slices";

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authSlice),
  app: persistReducer(appPersistConfig, appSlice),
  auction: persistReducer(auctionPersistConfig, auctionSlice),
  nofitication: persistReducer(notificationPersistConfig, notificationSlice),
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["app", "nofitication"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
