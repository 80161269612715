import InputField from "components/fields/InputField";
import { Controller, FieldValues, FieldPath } from "react-hook-form";
import { AppRemoteUpload } from "./AppRemoteUpload";

export const UploadController = (props: any) => {
  const {
    control,
    name,
    rules,
    defaultValue,
    disabledController,
    shouldUnregister,
    inputType,
    ...restProps
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      disabled={disabledController}
      shouldUnregister={shouldUnregister}
      render={({ field }) => {
        return <AppRemoteUpload onChangeListFile={field.onChange} {...props} />;
      }}
    />
  );
};
