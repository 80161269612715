import { PATH_ADMIN_DASHBOARD } from "navigation";
import { Navigate, Outlet } from "react-router-dom";

import { useAppSelector } from "store";

export const AuthRoute: React.FC = () => {
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  // if (import.meta.env.DEV) {
  //   return <Outlet />;
  // }

  return accessToken ? <Navigate to={PATH_ADMIN_DASHBOARD} /> : <Outlet />;
};
