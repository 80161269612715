import { IStatisticDestination } from "interfaces";

export const defaultParams = {
  page: 1,
  limit: 10,
  countryName: null as any,
  sort: null as any,
};

export const defaultDestinationData = {
  items: [] as IStatisticDestination[],
  limit: 10,
  page: 1,
  total: 0,
  totalPage: 0,
};
