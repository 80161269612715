import {
  upload,
  close,
  uploadButton,
  pdf,
  chevronDown,
  check,
  search,
  sortDown,
  sortUp,
  file,
} from "assets";

export const IconMap = {
  upload,
  uploadButton,
  close,
  pdf,
  chevronDown,
  check,
  search,
  sortDown,
  sortUp,
  file,
};
