import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import Select, {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  MultiValueRemoveProps,
  OptionProps,
  ValueContainerProps,
  components,
  CSSObjectWithLabel,
  GroupBase,
  ControlProps,
  MultiValueGenericProps,
} from "react-select";
import cn from "classnames";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { AppIcon } from "components/icons";
import { FlexWrap } from "components/styled/AppStyled";
import { BREAK_POINT, borderRadiusEnum } from "config";
import { Tooltip } from "@chakra-ui/tooltip";

interface IProps
  extends Omit<
    StateManagerProps,
    | "isDisabled"
    | "isClearable"
    | "selectClassName"
    | "menuPlacement"
    | "containerClassName"
    | "label"
    | "captionLabel"
    | "classNamePrefix"
    | "isSelectYear"
    | "isSearchable"
    | "noOptionsMessage"
    | "required"
  > {
  containerClassName?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  selectClassName?: string;
  menuPlacement?: "top" | "bottom" | "auto";
  label?: string | null;
  captionLabel?: string | null;
  required?: boolean;
  isSelectYear?: boolean;
  defaultSelectedValue?: any;
  isError?: boolean;
  dropdownIcon?: React.ReactNode;
  errors?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  controlCss?:
    | ((
        base: CSSObjectWithLabel,
        props: ControlProps<any, boolean, GroupBase<unknown>>
      ) => CSSObjectWithLabel)
    | undefined;
  valueContainerCss?:
    | ((
        base: CSSObjectWithLabel,
        props: ValueContainerProps<any, boolean, GroupBase<unknown>>
      ) => CSSObjectWithLabel)
    | undefined;
  optionCss?:
    | ((
        base: CSSObjectWithLabel,
        props: OptionProps<any, boolean, GroupBase<unknown>>
      ) => CSSObjectWithLabel)
    | undefined;
  showSearchIcon?: boolean;
}

export const ReactSelect = React.forwardRef<any, IProps>((props, ref) => {
  const {
    defaultSelectedValue,
    options,
    isClearable = false,
    selectClassName,
    containerClassName,
    menuPlacement = "auto",
    required,
    captionLabel,
    isSearchable = false,
    isError,
    dropdownIcon,
    errors,
    placeholder = "select",
    controlCss,
    valueContainerCss,
    optionCss,
    showSearchIcon = true,
    isMulti,
    ...restProps
  } = props;

  const theme = useTheme();

  const [selectDefaultValue, setSelectDefaultValue] = useState<any>();

  const DropdownIndicator = (dpProps: DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...dpProps}>
        {dropdownIcon ? (
          dropdownIcon
        ) : (
          <AppIcon
            size={16}
            className="app-expand-select-icon"
            name="chevronDown"
          />
        )}
      </components.DropdownIndicator>
    );
  };

  const MultiValueContainer = (props: MultiValueGenericProps<any>) => {
    const { data } = props;
    return (
      <>
        {data.label}
        {isMulti && ", "}
      </>
    );
  };

  const ValueContainer = (dpProps: ValueContainerProps) => {
    const { isSearchable } = props;
    return (
      <components.ValueContainer {...dpProps}>
        {isSearchable && showSearchIcon && (
          <AppIcon
            size={16}
            className="app-expand-select-icon"
            name="search"
            style={{ position: "absolute", left: 0 }}
          />
        )}
        {dpProps.children}
      </components.ValueContainer>
    );
  };

  const ClearIndicator = (props: ClearIndicatorProps) => {
    return (
      <components.ClearIndicator {...props}>
        <AppIcon className="clear-indicator-icon" name="close" size={14} />
      </components.ClearIndicator>
    );
  };

  const MultiValueRemove = (props: MultiValueRemoveProps) => {
    const { data, children } = props;
    if (
      typeof data === "object" &&
      data &&
      "isDisabled" in data &&
      (data as any).isDisabled === true
    )
      return null;

    return (
      <components.MultiValueRemove {...props}>
        {children}
      </components.MultiValueRemove>
    );
  };

  const Option = (props: OptionProps) => {
    const { isSelected, children } = props;

    return (
      <components.Option {...props}>
        <FlexWrap $align="center" $justifyContent="space-between">
          <div className="app-select-option__content text-base">{children}</div>
          {isSelected && (
            <AppIcon name="check" size={16} color={theme.colors.lightBlue300} />
          )}
        </FlexWrap>
      </components.Option>
    );
  };

  useEffect(() => {
    if (defaultSelectedValue) {
      if (options?.length) {
        const selectedOption = options?.find(
          (item: any) => item?.value === defaultSelectedValue
        );
        setSelectDefaultValue(selectedOption);
      } else {
        setSelectDefaultValue({
          label: String(defaultSelectedValue),
          value: defaultSelectedValue,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelectedValue, JSON.stringify(options)]);

  return (
    <Wrapper
      $error={!!errors}
      $isError={isError}
      className={cn("app-select-contain", containerClassName)}
    >
      <Select
        ref={ref}
        {...restProps}
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null,
          ClearIndicator,
          MultiValueRemove,
          MultiValueContainer,
          ValueContainer,
          Option,
          ...restProps?.components,
        }}
        classNamePrefix="app-select"
        className={cn("app-select", selectClassName)}
        noOptionsMessage={({ inputValue }) =>
          !inputValue ? String("noOptionsMessage") : String("noResultsMessage")
        }
        options={options}
        placeholder={placeholder}
        isMulti={isMulti}
        isClearable={isClearable}
        isSearchable={isSearchable}
        hideSelectedOptions={false}
        menuPlacement={menuPlacement}
        defaultValue={selectDefaultValue || props?.defaultValue}
        styles={{
          multiValue: (styles) => {
            return {
              ...styles,
              margin: "0 2px",
              display: "flex",
              alignItems: "center",
              borderRadius: "4px",
              padding: "2px",
              gap: "8px",
            };
          },
          valueContainer: (styles, props) => {
            return {
              ...styles,
              color: theme.colors.neutral900,
              fontSize: "0.875rem",
              fontStyle: "normal",
              fontWeight: 500,
              textTransform: "capitalize",
              padding: 0,
              cursor: "pointer",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
              ...(props.isDisabled && { pointerEvents: "auto" }),
              ...(isSearchable && showSearchIcon && { paddingLeft: 36 }),
              ...(valueContainerCss ? valueContainerCss(styles, props) : {}),
            };
          },
          singleValue: (styles) => {
            return { ...styles, margin: 0 };
          },
          multiValueLabel: (styles) => {
            return {
              ...styles,
              padding: 0,
              paddingLeft: 0,
              fontSize: "0.875rem",
            };
          },
          multiValueRemove: (styles, { isDisabled }) => {
            return {
              ...styles,
              padding: 0,
              ...(isDisabled && { display: "none" }),
            };
          },
          menu: (styles) => {
            return {
              ...styles,
              marginTop: 0,
              marginBottom: 0,
              textTransform: "capitalize",
              width: "max-content",
              minWidth: "100%",
              zIndex: 3,
              top: "calc(100% + 4px)",
              backgroundColor: theme.colors.white,
              boxShadow: "none",
              "@media (max-width: 768px)": {
                top: "calc(100% + 8px)",
              },
            };
          },
          menuList: (styles) => {
            return {
              ...styles,
              border: `1px solid ${theme.colors.neutral100}`,
              boxShadow: theme.boxShadow.shadowLg,
              borderRadius: borderRadiusEnum.MEDIUM,
              "::-webkit-scrollbar": {
                width: "8px",
                background: "transparent",
              },
              "::-webkit-scrollbar-thumb": {
                background: theme.colors.neutral200,
                borderRadius: borderRadiusEnum.MEDIUM,
              },
            };
          },
          control: (styles, props) => {
            return {
              ...styles,
              height: "3rem",
              width: "100%",
              border: "1px solid #E2E8F0",
              borderRadius: borderRadiusEnum.LARGE,
              boxShadow: "none",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              padding: "8px 12px",
              marginTop: "8px",
              ":hover": {
                borderColor: "#E2E8F0",
              },
              ...((isError || !!errors) && {
                borderColor: theme.colors.error600,
              }),
              ...(controlCss ? controlCss(styles, props) : {}),
            };
          },
          option: (styles, props) => {
            return {
              ...styles,
              fontWeight: 500,
              fontSize: "0.875rem",
              lineHeight: "1.4rem",
              fontStyle: "normal",
              color: theme.colors.neutral900,
              cursor: "pointer",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              backgroundColor: "#fff",
              ":hover": {
                backgroundColor: theme.colors.lightBlue50,
              },
              ...(props.isFocused && {
                backgroundColor: theme.colors.neutral50,
              }),
              ...(props.isSelected && {
                backgroundColor: theme.colors.neutral50,
              }),
              ...(props.isDisabled && {
                color: theme.colors.neutral600,
                cursor: "not-allowed",
              }),
              ...(optionCss ? optionCss(styles, props) : {}),
            };
          },
          placeholder: (styles) => {
            return {
              ...styles,
              fontSize: "0.875rem",
              fontWeight: 400,
              lineHeight: "1.875rem",
            };
          },
        }}
      />
      {!!errors && <p className="text-red-600">{String(errors)}</p>}
    </Wrapper>
  );
});

ReactSelect.displayName = "ReactSelect";

const Wrapper = styled.div<{
  $isError?: boolean;
  $error?: boolean;
}>`
  .select-input {
    cursor: pointer;
    height: 62px;
    max-width: 96px;
    min-width: 96px;
    padding: 0;
    width: fit-content;
    border-radius: 4px;
  }
  .select__label {
    display: inline-block;
    margin-bottom: 5px;
    color: ${(p) => p.theme.colors.neutral900};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
    text-transform: capitalize;

    .required {
      color: ${(p) => p.theme.colors.error600};
      font-weight: bold;
    }
  }
  .select__cap-label {
    font-size: 1.4rem;
    font-weight: 500;
    &::first-letter {
      text-transform: capitalize;
    }
  }

  .app-select {
    height: 52px;
  }

  .app-expand-select-icon {
    cursor: pointer;
    color: ${(p) => p.theme.colors.lightBlue300};
  }
  .app-select__indicator {
    padding: 0;
  }
  .clear-indicator-icon {
    color: ${(p) => p.theme.colors.neutral900};
    cursor: pointer;
  }

  .select-text-error {
    margin-top: 0.5rem;
    color: ${(p) => p.theme.colors.error600};
    font-size: 1.2rem;
    position: relative;

    top: -0.2rem;
    @media (max-width: ${BREAK_POINT.mobile}) {
      font-size: 1.1rem;
    }
  }
  .app-select__menu {
    width: 100%;
  }
  .app-select__indicators {
    height: 100%;
  }
  .app-select__single-value {
    line-height: 1.875rem;
  }
`;
