import {
  TextAreaFieldProps,
  type InputFieldProps,
} from "components/controller/type";
import { AppIcon } from "components/icons";
import { EyeCloseIcon, EyeIcon } from "components/icons/CustomIcon";
import { useState } from "react";

const TextAreaField = (props: TextAreaFieldProps) => {
  const {
    label,
    id,
    extra = "",
    placeholder = "",
    /** */
    variant = "auth",
    state,
    disabled,
    error,
    value,
    className = "",
    required = false,
    shouldClear = false,
    maxLength = 50,
    onChange,
    rows,
  } = props;

  const [inputValue, setInputValue] = useState<string>(value);

  const handleChange = (e: any) => {
    setInputValue(e.target.value);
    onChange(e);
  };

  const clearInput = () => {
    setInputValue("");
    onChange();
  };

  return (
    <div className={`${extra}`}>
      {label && (
        <label
          htmlFor={id}
          className={`flex text-sm text-navy-700 dark:text-white ${
            variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
          }`}
        >
          {label}
          {required && <p className="ml-1 text-red-600">*</p>}
        </label>
      )}
      <div className="relative">
        <textarea
          disabled={disabled}
          id={id}
          onChange={handleChange}
          value={inputValue}
          autoComplete="off"
          placeholder={placeholder}
          maxLength={maxLength}
          rows={rows}
          className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
            disabled === true
              ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
              : state === "error"
              ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
              : state === "success"
              ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
              : "border-gray-200 dark:!border-white/10 dark:text-white"
          } ${className}`}
        />
        {!!inputValue?.length && shouldClear && (
          <AppIcon
            name="close"
            onClick={clearInput}
            className="absolute bottom-1/2 right-0 mr-3	translate-y-1/2 cursor-pointer"
          />
        )}
      </div>
      {error && (
        <p className="mt-1 text-xs text-red-500 dark:text-red-400">{error}</p>
      )}
    </div>
  );
};

export default TextAreaField;
