import InputField from "components/fields/InputField";
import { Pagination } from "components/pagination/Pagination";
import { TableLayout } from "components/table/TaybleLayout";
import { useState, useEffect, useRef } from "react";
import { IInvoiceData, ITableColumn } from "interfaces";
import { IoEyeOutline } from "react-icons/io5";
import { LogApp, openPdfFromBase64, showAppToast } from "utils";
import PopoverHorizon from "components/popover";
import { PopoverBody } from "@chakra-ui/popover";
import { Button } from "components/button/Button";
import { defaultParams } from "./defaultValue";
import { FaDownload } from "react-icons/fa";
import { formatAppDate, getUTCTime } from "utils/appFormat";
import SelectDate from "components/selectDate";
import { Stack } from "@chakra-ui/react";
import { RadioGroup, Radio } from "@chakra-ui/radio";
import { EInvoiceDateRange, EStatusInvoice } from "enum";
import { invoiceAPI } from "api";
import dayjs from "dayjs";
import { PREV_DATE_FORMAT } from "config";
import {
  endOfLast2Years,
  endOfLastMonth,
  endOfLastYear,
  startOfLast2Years,
  startOfLastMonth,
  startOfLastYear,
} from "utils/date.util";
import { setLoading, useAppDispatch } from "store";
import { PreviewInvoiceModal } from "./ModalPreviewInvoice";
import { produce } from "immer";
import ModalConfirm from "./ModalConfirm";

export const Invoice = () => {
  const dispatch = useAppDispatch();

  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [invoices, setInvoices] = useState<IInvoiceData[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalResult, setTotalResult] = useState<number>();
  const [params, setParams] = useState(defaultParams);
  const [draftParams, setDraftParams] =
    useState<typeof defaultParams>(defaultParams);
  const [status, setStatus] = useState<EInvoiceDateRange | "">("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [invoiceDetail, setInvoiceDetail] = useState<string | undefined>();
  const selectedInvoice = useRef<IInvoiceData | undefined>();

  const isApply = useRef(false);

  const colums: ITableColumn<IInvoiceData>[] = [
    {
      key: "none",
      title: "No",
      render: (_, val: any) => (
        <div>{val.index + 1 + (params.page - 1) * 10}</div>
      ),
    },
    {
      key: "createdAt",
      title: "Issue date",
      render: (value) => <div>{formatAppDate(getUTCTime(value))}</div>,
    },
    {
      key: "companyName",
      title: "Recipient company",
    },
    {
      key: "fullName",
      title: "Representative name",
      // render: (_, val) => <>{}</>,
    },
    // {
    //   key: "status",
    //   title: "Status",
    //   render: (_, val) => (
    //     <>{val.status === EStatusInvoice.PAID ? "Paid" : "Unpaid"}</>
    //   ),
    // },
    {
      key: "none",
      title: "Action",
      render: (_, val2) => (
        <div className="ml-3">
          <IoEyeOutline
            className="cursor-pointer "
            onClick={() => handleViewDetail(val2)}
          />
        </div>
      ),
    },
    {
      key: "none",
      title: "Export",
      render: (_, value) => (
        <div className="ml-3">
          <FaDownload
            className="cursor-pointer "
            onClick={() => handleViewDetail(value, true)}
          />
        </div>
      ),
    },
    {
      key: "none",
      title: "",
      render: (_, value) => (
        <div className="">
          <Button
            onClick={() => {
              selectedInvoice.current = value;
              setShowModalConfirm(true);
            }}
            title={value.status === EStatusInvoice.PAID ? "Paid" : "Pay"}
            className={`h-[44px] px-4 text-center ${
              value.status === EStatusInvoice.PAID &&
              "cursor-not-allowed bg-gray-300 hover:bg-gray-300"
            }`}
            disabled={value.status === EStatusInvoice.PAID}
          />
        </div>
      ),
    },
  ];

  const paidInvoice = async (value: IInvoiceData) => {
    try {
      dispatch(setLoading(true));
      const res = await invoiceAPI.paidInvoice(value?.invoiceId);
      if (res.success) {
        setInvoices(
          produce(invoices, (draft) => {
            const index = draft.findIndex(
              (item) => item.invoiceId === value.invoiceId
            );
            draft[index].status = EStatusInvoice.PAID;
          })
        );
        showAppToast(
          `Success`,
          "success",
          "colored",
          "Paid invoice successfully"
        );
      }
    } catch (error) {
      LogApp(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getInvoices = async () => {
    try {
      setLoadingTable(true);
      const res = await invoiceAPI.getListInvoices(params);
      console.log("GET INVOICES RES", res);
      if (res?.success) {
        setInvoices(res?.data?.items ?? []);
        setTotalPage(+res.data.totalPage);
        setTotalResult(res.data.total);
      }
      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);
      LogApp(error);
    }
  };

  const handleViewDetail = async (value: IInvoiceData, download?: boolean) => {
    try {
      dispatch(setLoading(true));
      const res = await invoiceAPI.getInvoiceDetail(value?.invoiceId);
      console.log("GET INVOICE DETAIL RES", res);
      if (res.success)
        if (download)
          openPdfFromBase64(
            res?.data,
            `${value.companyName} - invoiceID_${value.createdAt} - ${value.createdAt}.pdf`
          );
        else {
          setInvoiceDetail(res?.data);
          setIsOpenModal(true);
        }
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleDraftParams = (params: Partial<typeof defaultParams>) => {
    setDraftParams((prev: any) => ({ ...prev, page: 1, ...params }));
  };

  const handleFilter = (
    newParams: Partial<typeof defaultParams>,
    callback?: () => void
  ) => {
    setLoadingTable(true);
    setInvoices([]);
    if (!!callback) {
      setParams((prev: any) => ({
        ...prev,
        page: newParams.page || 1,
        endDate: newParams?.endDate
          ? formatAppDate(newParams.endDate, PREV_DATE_FORMAT)
          : undefined,
        startDate: newParams?.startDate
          ? formatAppDate(newParams.startDate, PREV_DATE_FORMAT)
          : undefined,
        // search: params?.search || "",
      }));
      callback();
    } else {
      setParams((prev: any) => ({
        ...prev,
        ...newParams,
      }));
    }
  };
  const resetFilter = (callback: () => void) => {
    setStatus("");
    setParams((prev) => ({ ...defaultParams, search: prev.search }));
    setDraftParams(defaultParams);
    callback?.();
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      getInvoices();
    }, 500);

    return () => clearTimeout(getData);
  }, [params]);

  const handleDateRange = (value?: EInvoiceDateRange) => {
    setStatus(value);
    switch (value) {
      case EInvoiceDateRange.ALL_TIMES:
        handleDraftParams({ endDate: undefined, startDate: undefined });
        break;
      case EInvoiceDateRange.LAST_MONTH:
        handleDraftParams({
          startDate: startOfLastMonth(),
          endDate: endOfLastMonth(),
        });
        break;
      case EInvoiceDateRange.LAST_YEAR:
        handleDraftParams({
          startDate: startOfLastYear(),
          endDate: endOfLastYear(),
        });
        break;
      case EInvoiceDateRange.LAST_TWO_YEARS:
        handleDraftParams({
          startDate: startOfLast2Years(),
          endDate: endOfLast2Years(),
        });
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className="h-full w-full">
        <div className="mb-4 flex w-full flex-row items-end justify-between">
          <div className="flex w-full">
            <InputField
              id="Search"
              // shouldClear={true}
              value={params?.search || ""}
              placeholder="Search by invoiceId, recipient company, representative name"
              className="min-w-[460px] max-w-[50%] border-gray-600"
              onChange={(e: any) =>
                handleFilter({ search: e?.target?.value || "" })
              }
            />
            <PopoverHorizon
              trigger={
                <button className="mx-2 mt-2 rounded-xl border border-gray-600 px-8 text-sm">
                  Time range
                </button>
              }
              onClose={() => {
                console.log("CLOSE");
                if (isApply.current) {
                  return;
                }
                handleDraftParams({ endDate: undefined, startDate: undefined });
                setStatus("");
              }}
              renderContent={(onClose) => {
                return (
                  <PopoverBody>
                    <div className="my-2">
                      <RadioGroup
                        onChange={(status: EInvoiceDateRange) =>
                          handleDateRange(status)
                        }
                        value={status}
                      >
                        <Stack spacing={[1, 2]} direction="column" gap={4}>
                          {/* <Radio value="">All</Radio> */}
                          <div className="grid grid-cols-2 gap-x-2">
                            <Radio
                              className="col-span-1"
                              value={EInvoiceDateRange.ALL_TIMES}
                            >
                              All times
                            </Radio>
                            <Radio
                              className="col-span-1"
                              value={EInvoiceDateRange.LAST_YEAR}
                            >
                              Last year
                            </Radio>
                          </div>
                          <div className="grid grid-cols-2 gap-x-2">
                            <Radio
                              className="col-span-1"
                              value={EInvoiceDateRange.LAST_TWO_YEARS}
                            >
                              Last two years
                            </Radio>
                            <Radio
                              className="col-span-1"
                              value={EInvoiceDateRange.LAST_MONTH}
                            >
                              Last month
                            </Radio>
                          </div>
                        </Stack>
                      </RadioGroup>
                    </div>
                    <div className="my-2">
                      <p className="font-bold">Custom date:</p>
                      <SelectDate
                        onChange={([startDate, endDate]: [Date, Date]) => {
                          handleDraftParams({ endDate, startDate });
                          setStatus("");
                        }}
                        triggerOnChangeType="oneSelected"
                        value={[draftParams.startDate, draftParams.endDate]}
                        customInput={
                          <div className="flex w-full !justify-start">
                            {!!draftParams.startDate && (
                              <p>
                                {dayjs(draftParams.startDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            )}
                            {!!draftParams.startDate && <>&nbsp;-&nbsp;</>}
                            {!!draftParams.endDate && (
                              <p>
                                {dayjs(draftParams.endDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            )}
                          </div>
                        }
                      />
                    </div>
                    <div className="mt-3 text-center">
                      <Button
                        title="Clear"
                        className="mx-2 mt-0 !w-20 py-0.5"
                        onClick={() => {
                          isApply.current = false;
                          resetFilter(onClose);
                        }}
                      ></Button>
                      <Button
                        title="Apply"
                        className="mx-2 mt-0 !w-20 py-0.5"
                        onClick={() => {
                          if (
                            !!draftParams.startDate &&
                            !!draftParams.endDate
                          ) {
                            isApply.current = true;
                          }
                          handleFilter(draftParams, onClose);
                        }}
                      ></Button>
                    </div>
                  </PopoverBody>
                );
              }}
            />
          </div>
        </div>
        <TableLayout
          columns={colums}
          data={invoices}
          onDoubleClick={handleViewDetail}
          loading={loadingTable}
          minRows={10}
        />
        {!!totalResult && !loadingTable && (
          <div className="mx-3 flex items-center justify-between">
            <div>
              {totalResult > 1
                ? `${totalResult} results`
                : `${totalResult} result`}{" "}
            </div>
            <Pagination
              totalPage={totalPage}
              onChangePage={(e: any) => handleFilter({ page: e.selected + 1 })}
              forcePage={params.page - 1}
            />
          </div>
        )}
      </div>
      <PreviewInvoiceModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        data={invoiceDetail}
      />
      <ModalConfirm
        isOpen={showModalConfirm}
        onClose={() => {
          setShowModalConfirm(false);
          selectedInvoice.current = undefined;
        }}
        onConfirm={() => {
          if (selectedInvoice.current) {
            paidInvoice(selectedInvoice.current);
            setShowModalConfirm(false);
          }
        }}
      />
    </div>
  );
};
