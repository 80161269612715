import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";
import Widget from "components/widget/Widget";
import { Pagination } from "components/pagination/Pagination";
import { ISorter, TableLayout } from "components/table/TaybleLayout";
import {
  IStatisticDestination,
  IStatisticDestinationData,
  ITableColumn,
} from "interfaces";
import { useState, useEffect, ChangeEvent } from "react";
import { LogApp } from "utils";
import { defaultDestinationData, defaultParams } from "./defaultValue";
import { dashboardAPI } from "api/dashboardAPI";
import { Select } from "@chakra-ui/react";
import styled from "styled-components";
import { currencyAPI } from "api";
import { ECurrency, ECurrencyCharacter, ECurrencyCharacterKeys } from "config";
import ReactSelect from "react-select";

const Dashboard = () => {
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [destinationData, setDestinationData] =
    useState<IStatisticDestinationData>(defaultDestinationData);
  const [params, setParams] = useState<typeof defaultParams>(defaultParams);
  const [currency, setCurrency] = useState<ECurrencyCharacterKeys>(
    ECurrency.EUR
  );
  const [countries, setCountries] = useState<any[]>([]);
  const [listCurrency, setListCurrency] = useState<any>();
  const [statistic, setStatistic] = useState<any>("USD");

  const colums: ITableColumn<IStatisticDestination>[] = [
    {
      key: "country",
      title: "Country",
    },
    {
      key: "air",
      title: "Air",
    },
    {
      key: "ocean",
      title: "Ocean",
    },
    {
      key: "truck",
      title: "Truck",
    },
    {
      key: "rail",
      title: "Rail",
    },
    {
      key: "totalAuction",
      title: "Total Auction",
      isSortable: true,
    },
  ];

  const handlePageChange = (e: any) => {
    try {
      setLoadingTable(true);
      setDestinationData(defaultDestinationData);
      setParams((prev: any) => ({ ...prev, page: e.selected + 1 }));
    } catch (error) {}
  };

  const getPopularDestination = async () => {
    try {
      setLoadingTable(true);
      const res = (await dashboardAPI.getStatisticDestination(params)) as any;
      setDestinationData(res.data?.result);
      setLoadingTable(false);
    } catch (error) {
      LogApp(error);
      setLoadingTable(false);
    }
  };

  const getStatistic = async (currency: ECurrencyCharacterKeys) => {
    try {
      const res = (await dashboardAPI.getStatistic({
        currency,
      })) as any;
      setStatistic(res.data?.result);
      setCurrency(currency);
    } catch (error) {}
  };

  const getListCurrency = async () => {
    try {
      const res = (await currencyAPI.getList()) as any;
      // setListCurrency(res?.data);
      setListCurrency(
        res?.data.map((item: any) => ({
          value: item?.name,
          label: item?.name,
        }))
      );
    } catch (error) {}
  };

  const getListCountry = async () => {
    try {
      const res = (await dashboardAPI.getListCountry()) as any;
      // setCountries(
      //   res?.data?.map((item: any) => ({
      //     label: item?.name,
      //     value: item?.name,
      //   }))
      // );
      setCountries(res?.data);
    } catch (error) {}
  };

  const handleSort = (sort: ISorter<IStatisticDestination>) => {
    setParams((prev: any) => ({
      ...prev,
      sort: sort.value ? `${sort.key}:${sort.value}` : null,
    }));
  };

  const onChangeCurrency = (e: any) => {
    getStatistic(e.value as ECurrencyCharacterKeys);
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      getPopularDestination();
    }, 500);

    return () => clearTimeout(getData);
  }, [params]);

  useEffect(() => {
    getListCurrency();
    getStatistic(ECurrency.USD);
    getListCountry();
  }, []);
  return (
    <Wrapper>
      {listCurrency && (
        <div className={`currency-wrapper `}>
          <p className="title">Currency: </p>

          <ReactSelect
            options={listCurrency}
            defaultValue={listCurrency?.[0]}
            onChange={onChangeCurrency}
            className="react-select-container"
            classNamePrefix="react-select"
          />
          {/* <Select
            variant="unstyled"
            fontWeight={600}
            onChange={onChangeCurrency}
          >
            {listCurrency?.map((currency: any) => {
              return (
                <option value={currency?.name}>{currency?.name || ""}</option>
              );
            })}
          </Select> */}
        </div>
      )}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 3xl:grid-cols-4">
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Live auction"}
          subtitle={statistic?.liveAuctions || 0}
          children="Total number of live auctions"
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Value of auctions"}
          subtitle={`${ECurrencyCharacter[currency]}${
            statistic?.totalValue || 0
          }`}
          children="Total value of all confirmed auctions"
        />
        <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Total revenue"}
          subtitle={`${ECurrencyCharacter[currency]}${
            statistic?.totalRevenue || 0
          }`}
          children="Total revenue of all confirmed auctions"
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Auctions waiting to be confirmed"}
          subtitle={statistic?.total || 0}
          children={
            <div className="flex">
              <div className="mr-2">
                <p>By Shipper</p>
                <h4 className="py-1 text-2xl font-bold text-navy-700 dark:text-white">
                  {statistic?.manufacturerAuctions || 0}
                </h4>
              </div>
              <div className="ml-2">
                <p>By Forwarder</p>
                <h4 className="py-1 text-2xl font-bold text-navy-700 dark:text-white">
                  {statistic?.forwarderAuctions || 0}
                </h4>
              </div>
            </div>
          }
        />
      </div>

      {false && countries?.length > 0 && (
        <div className="most-popular-destination ">
          <p className="title">
            Most popular shipping destinations (countries)
          </p>
          <div className="country-wrapper">
            <p className="title">Country: </p>

            {/* <ReactSelect
              options={countries}
              defaultValue={"All"}
              onChange={() => {
                debugger;
              }}
              className="react-select-container"
              classNamePrefix="react-select"
            /> */}

            <Select
              variant="unstyled"
              fontWeight={600}
              placeholder="All"
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                setParams((prev: any) => ({
                  ...prev,
                  countryName: e.target.value,
                }))
              }
            >
              {countries?.map((currency: any) => {
                return (
                  <option className="p-2" value={currency?.name}>
                    {currency?.name || ""}
                  </option>
                );
              })}
            </Select>
          </div>
        </div>
      )}
      <div className="mt-12">
        <TableLayout
          columns={colums}
          data={
            destinationData?.items?.length > 0 ? destinationData?.items : []
          }
          loading={loadingTable}
          onSort={handleSort}
        />
        {!!destinationData.total && (
          <div className="mx-3 flex items-center justify-between">
            <div>
              {destinationData.total > 1
                ? `${destinationData.total} results`
                : `${destinationData.total} result`}{" "}
            </div>
            <Pagination
              totalPage={destinationData.totalPage}
              onChangePage={handlePageChange}
              forcePage={params.page - 1}
            />
          </div>
        )}
      </div>

      {/* Tables & Charts */}
    </Wrapper>
  );
};

export default Dashboard;

const Wrapper = styled.div`
  .currency-wrapper,
  .country-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12rem;
    height: 2.5rem;
    background-color: ${({ theme }) => theme.colors.white};
    align-self: end;
    border-radius: 3rem;
    padding: 0 0 0 1.2rem;
    cursor: default;
    margin: 1.4rem 0 1.4rem auto;

    .title {
      margin-right: 0.75rem;
    }
  }

  .most-popular-destination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;

    .title {
      font-weight: 600;
      font-size: 1.125rem;
    }
    .country-wrapper {
      margin: 0;
      width: 16rem;
    }

    .react-select__menu {
      right: 0;
      width: max-content;
    }
  }

  .react-select-container {
    .react-select__control {
      border: 0;
      box-shadow: none;
    }
    .react-select__indicator-separator {
      display: none;
    }
  }
`;
