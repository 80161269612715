import queryString from "query-string";

export const getQueryString = () => {
  return queryString.parse(window.location.search);
};

export const openPdfFromBase64 = (base64: string, fileName?: string) => {
  if (fileName) {
    const linkSource = `data:application/pdf;base64,${base64}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = "_blank";
    downloadLink.click();
    return;
  }
};

export const base64ToBlob = (base64: string, type: string) => {
  const byteString = atob(base64);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type });
};
