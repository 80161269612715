import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { authAPI } from "api";
import { PATH_ADMIN, PATH_FORGOT_PASSWORD } from "navigation";
import { InputController } from "components/controller/InputController";
import { loginSchema } from "schema";
import { Button } from "components/button/Button";
import { setAccessToken, setLoading, useAppDispatch } from "store";
import { useCommon } from "hook";
import { showAppToast } from "utils";

const defaultValues = {
  email: "",
  password: "",
};

type TData = typeof defaultValues;

export default function SignIn() {
  const { AppDispatch, AppNavigate } = useCommon();

  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(loginSchema),
    mode: "onChange",
  });

  const onSubmit = async (data: TData) => {
    try {
      dispatch(setLoading(true));
      const res = await authAPI.login(data);
      AppDispatch(setAccessToken(res.data?.accessToken));
      AppNavigate(PATH_ADMIN);
      showAppToast("Success", "success");
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10">
      {/* Sign in section */}
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="mt-[10vh] min-w-[30vw] max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]"
      >
        <h4 className="mb-2.5 text-center text-4xl font-bold text-navy-700 dark:text-white">
          Welcome back
        </h4>
        <p className="mb-9 ml-1 text-center text-base text-gray-600">
          Enter your details to get sign in to your account.
        </p>
        {/* Email */}
        <InputController
          control={control}
          name={"email"}
          required={true}
          id={"email"}
          label={"Email"}
          placeholder={"E.g. username@adamo.com"}
          extra="mb-3"
          error={errors.email?.message}
        />

        {/* Password */}
        <InputController
          control={control}
          required={true}
          name={"password"}
          id={"password"}
          label={"Password"}
          placeholder={"Enter your password"}
          extra="mb-3"
          type={"password"}
          error={errors.password?.message}
        />

        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href={PATH_FORGOT_PASSWORD}
          >
            Forgot password?
          </a>
        </div>
        <Button title="Sign In" type="submit" />
      </form>
    </div>
  );
}
