import { emailSchema, phoneNumberSchema, requireTextSchema } from "./schema";
import * as yup from "yup";

export const forwarderSchema = (mode: "new" | "detail") => {
  switch (mode) {
    case "new":
      return yup.object().shape({
        firstName: requireTextSchema("This field is required"),
        lastName: requireTextSchema("This field is required"),
        companyPhoneNumber: phoneNumberSchema(),
        email: emailSchema(),
        companyName: requireTextSchema("This field is required"),
        companyVatId: yup.string(),
        interestedCountry: yup.array(),
        originatedCountryId: yup.object().shape({ value: yup.string() }),
        status: yup.object().required("This field is required"),
        companyLogo: yup.array(),
        companyRegistrations: yup.array(),
        additionalDocument: yup.array(),
      });
    case "detail":
      return yup.object().shape({
        firstName: requireTextSchema("This field is required"),
        lastName: requireTextSchema("This field is required"),
        companyPhoneNumber: phoneNumberSchema(),
        email: emailSchema(),
        companyName: requireTextSchema("This field is required"),
        companyVatId: yup.string().required("This field is required"),
        interestedCountry: yup.array().min(1, "This field is required"),
        originatedCountryId: yup
          .object()
          .shape({ value: yup.string().required("This field is required") }),
        status: yup.object().required("This field is required"),
        companyLogo: yup.array().min(1, "This field is required"),
        companyRegistrations: yup.array().min(1, "This field is required"),
        additionalDocument: yup.array().min(1, "This field is required"),
      });
    default:
      break;
  }
};
