import InputField from "components/fields/InputField";
import { Pagination } from "components/pagination/Pagination";
import { TableLayout } from "components/table/TaybleLayout";
import { useState, useEffect } from "react";
import { IContactUsData, ITableColumn } from "interfaces";
import { IoEyeOutline } from "react-icons/io5";
import { LogApp, getQueryString } from "utils";
import { defaultParams } from "./defaultValue";
import { contactUsAPI } from "api";
import { ContactUsDetailModal } from "./ContactUsDetailModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PATH_ADMIN_CONTACT_US } from "navigation";

export const ContactUs = () => {
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [invoices, setInvoices] = useState<IContactUsData[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalResult, setTotalResult] = useState<number>();
  const [params, setParams] = useState(defaultParams);
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState<IContactUsData | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const colums: ITableColumn<IContactUsData>[] = [
    {
      key: "id",
      title: "No",
      render: (_, val) => {
        return <div>{val?.index + 1 + (params.page - 1) * 10}</div>;
      },
    },
    {
      key: "representative",
      title: "Representative name",
    },
    {
      key: "companyName",
      title: "Company name",
    },
    {
      key: "phone",
      title: "Phone",
    },
    {
      key: "email",
      title: "Email",
    },
    {
      key: "none",
      title: "Action",
      render: (_, val2) => (
        <div className="ml-3">
          <IoEyeOutline
            className="cursor-pointer "
            onClick={() => setSearchParams({ id: `${val2?.id || null}` })}
          />
        </div>
      ),
    },
  ];

  const getContactUs = async () => {
    try {
      setLoadingTable(true);
      const res = await contactUsAPI.getListContactUs(params);
      if (res?.success) {
        setInvoices(res?.data?.items ?? []);
        setTotalPage(+res.data.totalPage);
        setTotalResult(res.data.total);
      }
      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);
      LogApp(error);
    }
  };

  const handleViewDetail = async (id: number) => {
    try {
      setSearchParams({ id: `${id}` });
      const res = await contactUsAPI.getContactUsDetail(id);
      setSelected(res?.data);
      setShowModal(true);
    } catch (error) {}
  };

  const handleFilter = (
    params: Partial<typeof defaultParams>,
    callback?: () => void
  ) => {
    setLoadingTable(true);
    setInvoices([]);
    setParams((prev: any) => ({ ...prev, page: 1, ...params }));
    if (callback) {
      callback();
    }
  };
  const onCloseModal = () => {
    setShowModal(false);
    navigate(PATH_ADMIN_CONTACT_US);
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      getContactUs();
    }, 500);

    return () => clearTimeout(getData);
  }, [params]);

  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      handleViewDetail(+id);
    }
  }, [searchParams]);

  return (
    <div>
      <div className="h-full w-full">
        <div className="mb-4 flex w-full flex-row items-end justify-between">
          <div className="flex w-full">
            <InputField
              id="Search"
              shouldClear={true}
              value={params?.search}
              placeholder="Search by representative name, email"
              className="min-w-[360px] max-w-[30%] border-gray-600"
              onChange={(e: any) =>
                handleFilter({ search: e?.target?.value || "" })
              }
            />
          </div>
        </div>
        <TableLayout
          columns={colums}
          data={invoices}
          onDoubleClick={(val) => setSearchParams({ id: `${val?.id || null}` })}
          loading={loadingTable}
          minRows={10}
        />
        {!!totalResult && !loadingTable && (
          <div className="mx-3 flex items-center justify-between">
            <div>
              {totalResult > 1
                ? `${totalResult} results`
                : `${totalResult} result`}{" "}
            </div>
            <Pagination
              totalPage={totalPage}
              onChangePage={(e: any) => handleFilter({ page: e.selected + 1 })}
              forcePage={params.page - 1}
            />
          </div>
        )}
      </div>
      <ContactUsDetailModal
        isOpen={showModal}
        onClose={onCloseModal}
        data={selected}
      />
    </div>
  );
};
