import { auctionAPI } from "api/auctionAPI";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { formatAppDate } from "utils/appFormat";
import { AppIcon } from "components/icons";
import { PATH_ADMIN_AUCTION } from "navigation";
import { setLoading, useAppDispatch } from "store";
import { TableLayout } from "components/table/TaybleLayout";
import { ITableColumn } from "interfaces";
import { checkValue } from "utils";
import { Badge, Divider } from "@chakra-ui/react";
import { STATUS_COLOR } from "enum/status";

export const AuctionDetailPage = () => {
  const [auction, setAuction] = useState<any>({});
  const { id } = useParams();
  const navigator = useNavigate();
  const dispatch = useAppDispatch();

  const columns: ITableColumn<any>[] = [
    {
      key: "packageType",
      title: "Package type",
      render: () => <div>{auction?.shipment?.packageType}</div>,
    },
    auction?.transportType === "TRUCK"
      ? {
          key: "truckType",
          title: "Truck type",
          render: (_, val: any) => (
            <div>
              {val?.truckType !== "OTHER"
                ? val?.truckType
                : val?.truckTypeOther}
            </div>
          ),
        }
      : {
          key: "containerType",
          title: "Container type",
          render: (_, val: any) => (
            <div>
              {val?.containerType !== "OTHER"
                ? val?.containerType
                : val?.containerTypeOther}
            </div>
          ),
        },
    {
      key: "width",
      title: "W (CM)",
    },
    {
      key: "length",
      title: "L (CM)",
    },
    {
      key: "height",
      title: "H (CM)",
    },
    {
      key: "weight",
      title: "Weight (KG)",
    },
    {
      key: "numberOfUnits",
      title: "# of units",
    },
    {
      key: "stockable",
      title: "Stockable",
      render: (_, val: any) => <div>{checkValue(val.stockable)}</div>,
    },
  ];

  const getAuctionDetail = async () => {
    try {
      dispatch(setLoading(true));
      const res = await auctionAPI.getDetail(+id);
      if (res.success) {
        setAuction(res.data);
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const backToAuctionList = () => {
    navigator(-1);
  };

  useEffect(() => {
    getAuctionDetail();
  }, []);

  return (
    <div className="mb-12 px-12">
      <div>
        <div className="text-center text-3xl font-bold">Auction details</div>
        <div className="relative flex justify-center text-2xl font-bold">
          <AppIcon
            name="close"
            className="absolute right-0 cursor-pointer"
            size={32}
            onClick={backToAuctionList}
          />
        </div>
      </div>
      <div>
        <div className="mb-2 mt-5 text-xl font-bold leading-10">
          Auction information
        </div>
        <div className="ml-5 grid grid-cols-2 gap-4 ">
          <div>
            {/* <div className="flex text-base font-bold">
              <p className="mb-4 w-60 mr-2">From:</p>
              <p>{auction?.shipment?.pickUpAddress || ""}</p>
            </div>
            <div className="flex text-base font-bold">
              <p className="mb-4 w-60 mr-2">To:</p>
              <p>{auction?.shipment?.deliveryAddress || ""}</p>
            </div> */}
            <div className="flex text-base ">
              <p className="mb-4 mr-2 w-60 font-bold">Auction end date:</p>
              <p> {formatAppDate(auction.auctionEnd)}</p>
            </div>
            <div className="flex text-base ">
              <p className="mb-4 mr-2 w-60 font-bold">Lowest Bid:</p>
              <p> 100 {auction.currencies?.name || ""}</p>
            </div>
          </div>
          <div>
            <div>
              <div className="mb-4 flex text-base ">
                <p className="mr-2 w-60 font-bold">Auction end time:</p>
                <p> {formatAppDate(auction.auctionEnd, "hh:mm")}</p>
              </div>
              {/* <div className="flex text-base ">
                <p className="font-bold w-60 mr-2">Manufacturer:</p>
                <p> Ha Noi, Viet Nam</p>
              </div>
              <div className="flex text-ase ">
                <p className="font-bold w-60 mr-2">Transport type:</p>
                <p>{auction.transportType || ""}</p>
              </div> */}
              <div className="mb-4 flex text-base ">
                <p className="mr-2 w-60 font-bold">Status:</p>
                <Badge
                  colorScheme={
                    STATUS_COLOR[
                      `${
                        auction.status?.toUpperCase() as keyof typeof STATUS_COLOR
                      }`
                    ]
                  }
                >
                  {auction.status?.replaceAll("_", " ")}
                </Badge>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="mb-2 mt-5 text-xl font-bold leading-10">
          Shipment information
        </div>
        <div className="ml-5 grid grid-cols-2 gap-4">
          <div>
            <div className="flex text-base font-bold">
              <p className="mb-4 mr-2 w-60">Commodity:</p>
              <p className="font-normal">
                {auction?.shipment?.commodity || "_"}
              </p>
            </div>
            <div className="flex text-base font-bold">
              <p className="mb-4 mr-2 w-60">Shipment ID:</p>
              <p className="font-normal">
                {auction?.shipment?.shipmentId || "_"}
              </p>
            </div>
            <div className="flex text-base font-bold">
              <p className="mb-4 mr-2 w-60">Custom clearance:</p>
              <p className="font-normal">
                {checkValue(auction?.shipment?.customClearance)}
              </p>
            </div>
            <div className="flex text-base font-bold">
              <p className="mb-4 mr-2 w-60">Insurance:</p>
              <p className="font-normal">
                {!!auction?.shipment?.insurance
                  ? `Yes - ${auction?.shipment?.insuranceContent}`
                  : "No"}
              </p>
            </div>
          </div>
          <div>
            <div>
              <div className="flex text-base font-bold">
                <p className="mb-4 mr-2 w-60">
                  Packing and shipment information:
                </p>
                <p className="font-normal">
                  {auction?.shipment?.packingShipmentInformation || "_"}
                </p>
              </div>
              <div className="flex text-base font-bold">
                <p className="mb-4 mr-2 w-60">Temperature sensitive:</p>
                <p className="font-normal">
                  {!!auction?.shipment?.temperatureSensitive
                    ? `Yes - ${auction?.shipment?.temperatureSensitiveNumber}℃`
                    : "No"}
                </p>
              </div>
              <div className="flex text-base font-bold">
                <p className="mb-4 mr-2 w-60">ADR/DGR:</p>
                <p className="font-normal">
                  {checkValue(auction?.shipment?.adrDgr)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="mb-2 mt-5 text-xl font-bold leading-10">
          Delivery information
        </div>
        <div className="my-4 ml-5 flex">
          <div className="mr-16 flex w-1/2 text-base font-bold">
            <p className="mr-2 w-60">Pick up from:</p>
            <p className="font-normal">
              {auction?.shipment?.pickUpType || "_"}
            </p>
          </div>
          <div className="ml-4 mr-16 flex w-1/2 text-base font-bold">
            <p className="mr-2 w-60">Address:</p>
            <p className="font-normal">
              {auction?.shipment?.pickUpAddress || "_"}
            </p>
          </div>
        </div>
        <Divider />
        <div className="my-4 ml-5 flex">
          <div className="mr-16 flex w-1/2 text-base font-bold">
            <p className=" mr-2 w-60">Delivery to:</p>
            <p className="font-normal">
              {auction?.shipment?.deliveryType || "_"}
            </p>
          </div>
          <div className="ml-4 mr-16 flex w-1/2 text-base font-bold">
            <p className="mr-2 w-60">Address:</p>
            <p className="font-normal">
              {auction?.shipment?.deliveryAddress || "_"}
            </p>
          </div>
        </div>
        <Divider />
        <div className="my-4 ml-5 flex">
          <div className="mr-16 flex w-1/2 text-base font-bold">
            <p className=" mr-2 w-60">Ready date:</p>
            <p className="font-normal">
              {formatAppDate(auction?.shipment?.readyDate)}
            </p>
          </div>
          <div className="ml-4 mr-16 flex w-1/2 text-base font-bold">
            <p className=" mr-2 w-60">Delivery date:</p>
            <p className="font-normal">
              {formatAppDate(auction?.shipment?.deliveryDate)}
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="mb-2 mt-5 text-xl font-bold leading-10">
          Package information
        </div>
        {auction?.shipment?.packages && (
          <TableLayout
            columns={columns}
            data={auction?.shipment?.packages}
            loading={false}
          />
        )}
      </div>
    </div>
  );
};
