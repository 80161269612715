import { API_ENDPOINT } from "enum";
import { AxiosService } from "./axiosService";

export const manufactureAPI = {
  getList: (params: any) => {
    const url = API_ENDPOINT.MANUFACTURER;
    return AxiosService.get(url, { params });
  },
  getDetal: (id: string | number) => {
    const url = `${API_ENDPOINT.MANUFACTURER}/${id}`;
    return AxiosService.get(url);
  },
  update: (id: string | number, body: any) => {
    const url = `${API_ENDPOINT.MANUFACTURER}/${id}`;
    return AxiosService.put(url, body);
  },
  addNew: (body: any) => {
    const url = `${API_ENDPOINT.MANUFACTURER}`;
    return AxiosService.post(url, body);
  },
  getListIndustry: () => {
    return AxiosService.get(API_ENDPOINT.COMPANY_INDUSTRY);
  },
};
