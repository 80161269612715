import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button, Textarea } from "@chakra-ui/react";
import TextAreaField from "components/fields/AreaFIeld";
import InputField from "components/fields/InputField";
import { IContactUsData } from "interfaces";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  data?: IContactUsData;
};

export const ContactUsDetailModal = (props: Props) => {
  const { isOpen, onClose, data } = props;

  const handleCloseModal = () => {
    onClose();
  };
  return (
    <Modal isCentered isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent className="!mb-0 !mt-0 max-h-[90vh] min-w-[980px] max-w-[75vw] overflow-auto">
        <ModalHeader className="text-center">Contact Us</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="grid grid-cols-2 gap-6">
            <InputField
              id="representativeName"
              label="Representative name"
              disabled
              value={data?.representative}
            />
            <InputField
              id="companyName"
              label="Company name"
              disabled
              value={data?.companyName}
            />
          </div>
          <div className="mt-6 grid grid-cols-2 gap-6">
            <InputField id="phone" label="Phone" disabled value={data?.phone} />
            <InputField id="email" label="Email" disabled value={data?.email} />
          </div>
          <TextAreaField
            id="message"
            label="Message"
            disabled
            value={data?.contactMessage}
            rows={10}
            extra="mt-6"
          />
          <div className="mt-5 flex justify-center">
            <Button
              variant="outline"
              style={{ width: "100px" }}
              onClick={handleCloseModal}
            >
              Back
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
