import InputField from "components/fields/InputField";
import { Pagination } from "components/pagination/Pagination";
import { TableLayout } from "components/table/TaybleLayout";
import { useState, useEffect } from "react";
import { IAuction, ITableColumn } from "interfaces";
import { IoEyeOutline } from "react-icons/io5";
import { LogApp, handleFullName } from "utils";
import { EAuctionStatus, ETransportType, STATUS, STATUS_COLOR } from "enum";
import { defaultParams } from "./defaultValue";
import PopoverHorizon from "components/popover";
import { PopoverBody } from "@chakra-ui/popover";
import { RadioGroup, Radio } from "@chakra-ui/radio";
import {
  Badge,
  Checkbox,
  CheckboxGroup,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import SelectDate from "components/selectDate";
import { auctionAPI } from "api/auctionAPI";
import { formatAppDate } from "utils/appFormat";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PATH_ADMIN_AUCTION } from "navigation";
import { Button } from "components/button/Button";
import dayjs from "dayjs";

export const AuctionPage = () => {
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [forwarders, setForwarders] = useState<IAuction[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalResult, setTotalResult] = useState<number>();
  const [params, setParams] = useState<typeof defaultParams>(defaultParams);
  const [draftParams, setDraftParams] =
    useState<typeof defaultParams>(defaultParams);

  const [searchParams, setSearchParams] = useSearchParams({
    searchKey: "",
    status: "",
    transportTypes: "",
    readyDateStart: "",
    readyDateEnd: "",
    page: "1",
    limit: "10",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const transportTypes = searchParams.get("transportTypes");
    const readyDateStart = searchParams.get("readyDateStart");
    const readyDateEnd = searchParams.get("readyDateEnd");
    const newParams = {
      searchKey: searchParams.get("searchKey") || null,
      status: searchParams.get("status") || null,
      transportTypes:
        transportTypes?.length > 0 ? transportTypes?.split("_") : [],
      readyDateStart: readyDateStart
        ? dayjs(readyDateStart, "YYYY-MM-DD").toDate()
        : null,
      readyDateEnd: readyDateEnd
        ? dayjs(readyDateEnd, "YYYY-MM-DD").toDate()
        : null,
      page: +searchParams.get("page") || 1,
      limit: 10,
    };
    LogApp("SEARCH PARAMS", newParams);
    setParams(newParams);
    setDraftParams(newParams);
  }, [searchParams]);

  const colums: ITableColumn<IAuction>[] = [
    {
      key: "id",
      title: "No",
      render: (_, val: any) => (
        <div>{val.index + 1 + (params.page - 1) * 10}</div>
      ),
    },
    {
      key: "shipment.id",
      title: "Shipment ID",
      render: (_, val: any) => <div>{val.shipment?.shipmentId}</div>,
    },
    {
      key: "transportType",
      title: "Transport type",
    },
    {
      key: "shipment.readyDate",
      title: "Ready Date",
      render: (_, val: any) => (
        <div>{formatAppDate(val.shipment?.readyDate)}</div>
      ),
    },
    {
      key: "status",
      title: "Status",
      render: (_, val: any) => (
        <>
          <Badge
            colorScheme={
              STATUS_COLOR[
                `${val.status?.toUpperCase() as keyof typeof STATUS_COLOR}`
              ]
            }
          >
            {val.status.replaceAll("_", " ")}
          </Badge>
        </>
      ),
    },
    {
      key: "none",
      title: "Action",
      render: (_, val2) => (
        <div className="ml-3">
          <IoEyeOutline
            className="cursor-pointer "
            onClick={() => handleViewDetail(val2)}
          />
        </div>
      ),
    },
  ];

  const getForwarders = async () => {
    try {
      setLoadingTable(true);
      const res = (await auctionAPI.getList(params)) as any;
      if (res?.success) {
        setForwarders(
          res.data.items.map((item: any, index: number) => ({
            ...item,
            index: ++index,
            name: handleFullName(item.firstName, item.lastName),
            isActive: item.isActive ? STATUS.active : STATUS.inactive,
          }))
        );
        setTotalPage(+res.data.totalPage);
        setTotalResult(res.data.total);
      }
      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);
      LogApp(error);
    }
  };

  const handleViewDetail = async (value: IAuction) => {
    navigate(`${PATH_ADMIN_AUCTION}/${value.id}`);
  };

  const handleDraftParams = (params: any) => {
    setDraftParams((prev: any) => ({ ...prev, page: 1, ...params }));
  };

  const updateSearchParams = (params: any) => {
    if (params.searchKey) {
      searchParams.set("searchKey", params.searchKey);
    } else searchParams.delete("searchKey");
    if (params.status) {
      searchParams.set("status", params.status);
    } else searchParams.delete("status");
    if (!!params.transportTypes?.length) {
      searchParams.set("transportTypes", params.transportTypes?.join("_"));
    } else searchParams.delete("transportTypes");
    if (params.readyDateStart) {
      searchParams.set(
        "readyDateStart",
        dayjs(params.readyDateStart).format("YYYY-MM-DD")
      );
    } else searchParams.delete("readyDateStart");
    if (params.readyDateEnd) {
      searchParams.set(
        "readyDateEnd",
        dayjs(params.readyDateEnd).format("YYYY-MM-DD")
      );
    } else searchParams.delete("readyDateEnd");
    searchParams.set("page", params.page);
    searchParams.set("limit", params.limit);
    setSearchParams(searchParams);
  };

  const handleFilter = (comingParams: any, callback?: () => void) => {
    setLoadingTable(true);
    setForwarders([]);
    const newParams = { ...params, page: 1, ...comingParams };
    setParams((prev: any) => ({ ...prev, page: 1, ...comingParams }));
    updateSearchParams({ ...newParams });
    if (callback) {
      callback();
    }
  };
  const resetFilter = (callback: () => void) => {
    setParams((prev) => ({ ...defaultParams, searchKey: prev.searchKey }));
    setDraftParams(defaultParams);
    callback?.();
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      getForwarders();
    }, 500);

    return () => clearTimeout(getData);
  }, [params]);

  return (
    <div>
      <div className="h-full w-full">
        <div className="mb-4 flex w-full flex-row items-end justify-between">
          <div className="flex w-full">
            <InputField
              id="Search"
              shouldClear={true}
              placeholder="Search by Shipment id"
              className="min-w-[360px] max-w-[30%] border-gray-600"
              onChange={(e: any) =>
                handleFilter({ searchKey: e?.target?.value || "" })
              }
              value={params?.searchKey}
            />
            <PopoverHorizon
              trigger={
                <button className="mx-2 mt-2 rounded-xl border border-gray-600 p-2 text-sm">
                  Filter
                </button>
              }
              renderContent={(onClose) => {
                return (
                  <PopoverBody>
                    <div className="my-2">
                      <p className="mb-2 font-bold">Status:</p>

                      <RadioGroup
                        onChange={(status: any) =>
                          handleDraftParams({ status })
                        }
                        value={draftParams.status}
                      >
                        <Stack spacing={[1, 2]} direction="column">
                          <Radio value="">All</Radio>
                          <Radio value={EAuctionStatus.inProgress}>
                            In progress
                          </Radio>
                          <Radio value={EAuctionStatus.finished}>
                            Finished
                          </Radio>
                          <Radio value={EAuctionStatus.confirmedByShipper}>
                            Confirmed by shippper
                          </Radio>
                          <Radio value={EAuctionStatus.confirmedByForwarder}>
                            Confirmed by forwarder
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </div>

                    <div className="my-2">
                      <p className="mb-2 font-bold">Transport type:</p>
                      <CheckboxGroup
                        colorScheme="blue"
                        onChange={(transportTypes: any) =>
                          handleDraftParams({ transportTypes })
                        }
                        value={draftParams.transportTypes}
                      >
                        <SimpleGrid columns={2} spacing={1}>
                          <Checkbox value={ETransportType.ocean}>
                            Ocean
                          </Checkbox>
                          <Checkbox value={ETransportType.air}>Air</Checkbox>
                          <Checkbox value={ETransportType.truck}>
                            Truck
                          </Checkbox>
                          <Checkbox value={ETransportType.rail}>Rail</Checkbox>
                        </SimpleGrid>
                      </CheckboxGroup>
                    </div>
                    <div className="my-2">
                      <p className="font-bold">Ready date:</p>
                      <SelectDate
                        onChange={([readyDateStart, readyDateEnd]: [
                          Date,
                          Date
                        ]) =>
                          handleDraftParams({ readyDateStart, readyDateEnd })
                        }
                        value={[
                          draftParams.readyDateStart,
                          draftParams.readyDateEnd,
                        ]}
                      />
                    </div>
                    <div className="mt-3 text-center">
                      <Button
                        title="Clear"
                        className="mx-2 mt-0 !w-20 py-0.5"
                        onClick={() => resetFilter(onClose)}
                      ></Button>
                      <Button
                        title="Apply"
                        className="mx-2 mt-0 !w-20 py-0.5"
                        onClick={() => {
                          const newParams = { ...draftParams };
                          delete newParams.searchKey;
                          handleFilter(newParams, onClose);
                        }}
                      ></Button>
                    </div>
                  </PopoverBody>
                );
              }}
            />
          </div>
        </div>
        <TableLayout
          columns={colums}
          data={forwarders}
          onDoubleClick={handleViewDetail}
          loading={loadingTable}
        />
        {!!totalResult && !loadingTable && (
          <div className="mx-3 flex items-center justify-between">
            <div>
              {totalResult > 1
                ? `${totalResult} results`
                : `${totalResult} result`}{" "}
            </div>
            <Pagination
              totalPage={totalPage}
              onChangePage={(e: any) => handleFilter({ page: e.selected + 1 })}
              forcePage={params.page - 1}
            />
          </div>
        )}
      </div>
    </div>
  );
};
