import { API_ENDPOINT } from "enum";
import { AxiosService } from "./axiosService";

export const notificationAPI = {
  getList: (params: any, controlller: AbortController) => {
    controlller.abort();
    const url = API_ENDPOINT.NOTIFICATION;
    return AxiosService.get(url, { params });
  },
  markAsRead: (id: number | string) => {
    const url = `${API_ENDPOINT.NOTIFICATION}/${id}`;
    return AxiosService.put(url, { id });
  },
  markAllRead: () => {
    const url = API_ENDPOINT.NOTIFICATION;
    return AxiosService.put(url);
  },
  registerFirebaseToken: (payload: {
    token: string;
    deviceId: string;
    deviceType: string;
  }) => {
    return AxiosService.post<any>(
      API_ENDPOINT.REGISTER_FIREBASE_TOKEN,
      payload
    );
  },
};
