import { API_ENDPOINT } from "enum";
import { AxiosService } from "./axiosService";
import { IInvoiceData, TPaging } from "interfaces";

export const invoiceAPI = {
  getListInvoices: (params: any) => {
    const url = API_ENDPOINT.INVOICE;
    return AxiosService.get<TPaging<IInvoiceData>>(url, { params });
  },
  getInvoiceDetail: (id: number) => {
    const url = `${API_ENDPOINT.INVOICE}/${id}`;
    return AxiosService.get<string>(url);
  },
  paidInvoice: (id: number) => {
    const url = `${API_ENDPOINT.INVOICE}/${id}`;
    return AxiosService.put<string>(url);
  },
};
