export const STATUS = {
  active: "ACTIVE",
  inactive: "INACTIVE",
  frozen: "FROZEN",
};
export const STATUS_COLOR = {
  ACTIVE: "green",
  INACTIVE: "red",
  FROZEN: "gray",
  IN_PROGRESS: "pink",
  FINISHED: "blue",
  CONFIRMED_BY_SHIPPER: "cyan",
  CONFIRMED_BY_FORWARDER: "purple",
};
export enum EAuctionStatus {
  inProgress = "IN_PROGRESS",
  finished = "FINISHED",
  confirmedByShipper = "CONFIRMED_BY_SHIPPER",
  confirmedByForwarder = "CONFIRMED_BY_FORWARDER",
}
export enum ETransportType {
  ocean = "OCEAN",
  air = "AIR",
  truck = "TRUCK",
  rail = "RAIl",
}
export enum EPackageType {
  FCL = "FCL", // Full Container Load
  LCL = "LCL", // Less than Container Load
  BREAK_BULK = "BREAK_BULK", // Free load
  NOT_SURE = "NOT_SURE", // Like LCL
}
export type IStatusKey = "active" | "inactive" | "frozen";

export enum EInvoiceDateRange {
  ALL_TIMES = "ALL_TIMES",
  LAST_MONTH = "THIS_MONTH",
  LAST_TWO_YEARS = "LAST_TWO_YEARS",
  LAST_YEAR = "LAST_YEAR",
}

export enum EStatusInvoice {
  UNPAID = "UNPAID",
  PAID = "PAID",
}
