import { SyntheticEvent, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

export const SelectDate = (props: {
  className?: string;
  placeholder?: string;
  onChange?: (date: [Date, Date]) => void;
  isClearable?: boolean;
  value?: [Date, Date];
  customInput?: React.ReactNode;
  triggerOnChangeType?: "bothSelected" | "oneSelected";
}) => {
  const {
    className,
    placeholder = "Select date",
    onChange,
    isClearable = true,
    value = [null, null],
    customInput,
    triggerOnChangeType = "bothSelected",
  } = props;
  const [dateRange, setDateRange] = useState(value);
  const [startDate, endDate] = dateRange;

  const handleChange = (date: [Date, Date], e: SyntheticEvent) => {
    setDateRange(date);
    const [startDate, endDate] = date;
    if (triggerOnChangeType === "oneSelected") {
      onChange?.(date);
      return;
    }
    if ((startDate && endDate) || (!startDate && !endDate)) {
      onChange?.(date);
    }
    return;
  };

  useEffect(() => {
    setDateRange(value);
  }, [value]);
  return (
    <Wrapper>
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={handleChange}
        placeholderText={placeholder}
        isClearable={isClearable}
        customInput={customInput}
        className={`flex h-12 w-full min-w-[186px] max-w-[100%] items-center justify-center rounded-xl border border-gray-200 border-gray-600 bg-white/0 p-3 text-sm outline-none dark:!border-white/10 dark:text-white ${className}`}
      />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  margin-top: 10px;

  .react-datepicker-wrapper input {
    padding: 0.75rem;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
`;
export default SelectDate;
