import { API_ENDPOINT } from "enum";
import { AxiosService } from "./axiosService";

export const forwarderAPI = {
  getList: (params: any) => {
    const url = API_ENDPOINT.FORWARDER;
    return AxiosService.get(url, { params });
  },
  getDetal: (id: string | number) => {
    const url = `${API_ENDPOINT.FORWARDER}/${id}`;
    return AxiosService.get(url);
  },
  update: (id: string | number, body: any) => {
    const url = `${API_ENDPOINT.FORWARDER}/${id}`;
    delete body.declined;
    return AxiosService.patch(url, body);
  },
  addNew: (body: any) => {
    const url = `${API_ENDPOINT.FORWARDER}`;
    return AxiosService.post(url, body);
  },
  getListCountry: () => {
    return AxiosService.get(API_ENDPOINT.COUNTRY);
  },
  getListCountryWithArea: () => {
    return AxiosService.get(API_ENDPOINT.COUNTRY, {
      params: {
        type: "INTERESTED_COUNTRY",
      },
    });
  },
};
