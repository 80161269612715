import * as yup from "yup";

import {
  emailValidationRegex,
  haveCharacterAndNumberPasswordValidationRegex,
  onlyTextRegex,
  otpValidationRegex,
  phoneNumberRegex,
  strongPasswordValidationRegex,
} from "config";

export const emailSchema = (
  requireMessage: string = "This field is required",
  emailMessage: string = "Email invalid",
  matchEmailRegexMessage: string = "Email invalid"
) => {
  return yup
    .string()
    .trim()
    .required(requireMessage)
    .email(emailMessage)
    .matches(emailValidationRegex, matchEmailRegexMessage);
};

export const requireTextSchema = (
  requireMessage: string = "This field is required"
) => {
  return yup.string().trim().required(requireMessage);
};

/**
 * at least one lowercase letter, one uppercase letter, one special character, and one digit.
 */
export const strongPasswordSchema = (
  requireMessage: string = "This field is required",
  matchMessage: string = "Password must have at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character."
) => {
  return yup
    .string()
    .trim()
    .required(requireMessage)
    .matches(strongPasswordValidationRegex, matchMessage);
};

export const confirmPassword = (
  reference: string = "password",
  requireMessage: string = "This field is required",
  matchMessage: string = "Password is not match"
) => {
  return yup
    .string()
    .trim()
    .required(requireMessage)
    .oneOf([yup.ref(reference), null], matchMessage);
};

/**
 * at least one letter and one digit.
 */
export const haveCharacterAndNumberPasswordSchema = (
  requireMessage: string = "This field is required",
  minMessage: string = "passwordLengthInvalid",
  maxMessage: string = "passwordLengthInvalid",
  matchMessage: string = "passwordFormatInvalid"
) => {
  return yup
    .string()
    .trim()
    .required(requireMessage)
    .min(8, minMessage)
    .max(32, maxMessage)
    .matches(haveCharacterAndNumberPasswordValidationRegex, matchMessage);
};

export const confirmPasswordSchema = (
  ref: string[] = ["password"],
  requireMessage: string = "This field is required",
  refMessage: string = "Password is not match"
) => {
  return yup
    .string()
    .required(requireMessage)
    .oneOf(
      ref.map((r) => yup.ref(r)),
      refMessage
    );
};

export const name = (
  requireMessage: string = "lastNameRequired",
  length: number = 50,
  lengthMessage: string = "maxCharacters",
  onlyTextMessage: string = "onlyHaveText"
) => {
  return yup
    .string()
    .trim()
    .required(require)
    .max(length, lengthMessage)
    .matches(onlyTextRegex, onlyTextMessage);
};

export const otpSchema = (
  requireMessage: string = "This field is required",
  matchEmailRegexMessage: string = "OTP must only include digits"
) => {
  return yup
    .string()
    .trim()
    .required(requireMessage)
    .matches(otpValidationRegex, matchEmailRegexMessage)
    .max(6, "OTP must be exactly 6 digits")
    .min(6, "OTP must be exactly 6 digits");
};

export const phoneNumberSchema = (
  requireMessage: string = "This field is required",
  matchRegexMessage: string = "Phone number must include from 8 to 13 number."
) => {
  return yup
    .string()
    .trim()
    .required(requireMessage)
    .matches(phoneNumberRegex, matchRegexMessage);
};
