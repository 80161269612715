import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Button } from "components/button/Button";
import { resetPasswordSchema } from "schema";
import { InputController } from "components/controller/InputController";
import { PATH_RESET_PASSWORD_SUCCESS } from "navigation";
import { authAPI } from "api";
import { useCommon } from "hook";
import { RootState } from "store";

const defaultValues = {
  password: "",
  confirmPassword: "",
};

type TData = typeof defaultValues;

export default function ResetPassword() {
  const { AppNavigate, useSelector } = useCommon();
  const hash = useSelector((state: RootState) => state.auth.resetPassHash);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(resetPasswordSchema),
    reValidateMode: "onChange",
  });

  const onSubmit = async (data: TData) => {
    try {
      await authAPI.resetPassword({ newPassword: data.password, hash });
      AppNavigate(PATH_RESET_PASSWORD_SUCCESS);
    } catch (error) {}
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10">
      {/* Sign in section */}
      <div className="mt-[10vh] min-w-[30vw] max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-center text-4xl font-bold text-navy-700 dark:text-white">
          Reset password
        </h4>
        <p className="mb-9 ml-1 text-center text-base text-gray-600">
          Please kindly set your new password
        </p>
        {/* Password */}
        <InputController
          control={control}
          name={"password"}
          id={"password"}
          label={"New password"}
          placeholder={"Min. 8 characters"}
          extra="mb-3"
          type={"password"}
          error={errors.password?.message}
        />

        <InputController
          control={control}
          name={"confirmPassword"}
          id={"confirmPassword"}
          label={"Re-enter password"}
          placeholder={"Min. 8 characters"}
          extra="mb-3"
          type={"password"}
          error={errors.confirmPassword?.message}
        />

        <Button title="Send" onClick={handleSubmit(onSubmit)} />
      </div>
    </div>
  );
}
