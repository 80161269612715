import axios, { type AxiosInstance } from "axios";
import queryString from "query-string";
import i18n from "i18next";
import { LogApp, removeFormLS, showAppToast } from "utils";
import { logout, store } from "store";
import { AUTH_TOKEN, appLanguageEnum } from "config";
import md5 from "md5";
import dayjs from "dayjs";
import { PATH_SIGN_IN } from "navigation";

export const axiosClient = (baseURL?: string): AxiosInstance => {
  const instance = axios.create({
    baseURL: baseURL ?? process.env.REACT_APP_API_URL,
    // timeout: 5000,
    headers: {
      "Content-Type": "application/json",
    },
    paramsSerializer: (params) => queryString.stringify(params),
  });

  instance.interceptors.request.use(
    async function (config) {
      const auth = store.getState()?.auth;
      const app = store.getState()?.app;
      const storeToken = auth?.accessToken;
      const language = app?.language ?? appLanguageEnum.EN;
      // LogApp("pre interceptors token", auth)
      // LogApp("pre interceptors token", accessToken, storeToken)
      if (storeToken) {
        LogApp("interceptors token", storeToken);
        config.headers.Authorization = `Bearer ${storeToken}`;
      }
      const uploadURL = `${process.env.REACT_APP_API_URL}/upload`;

      if (`${config.baseURL}${config.url}`.startsWith(uploadURL)) {
        config.headers["hash"] = md5(
          `${process.env.REACT_APP_SCRET_UPLOAD + dayjs().format("DD/MM/YYYY")}`
        );
        config.headers["Content-Type"] = "multipart/form-data";
      }
      config.headers["Accept-Language"] = ["en, fr"];
      config.headers.locale = language;
      return { ...config };
    },
    function (error) {
      // Do something with request error
      if (error?.response?.code === 401) {
        showAppToast(`${i18n.t("sessionExpired")}`, "warning");
        store.dispatch(logout());
        removeFormLS(AUTH_TOKEN);
      }
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      if (response && response?.data) {
        return response.data;
      }
      return response;
    },
    function (error) {
      if (error?.response?.status === 401) {
        store.dispatch(logout());
        removeFormLS(AUTH_TOKEN);
      } else {
        showAppToast(error?.response?.data?.errors || `Request error`, "error");
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export const ApiClient = axiosClient();
