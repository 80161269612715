import { Button } from "components/button/Button";
import InputField from "components/fields/InputField";
import { Pagination } from "components/pagination/Pagination";
import { TableLayout } from "components/table/TaybleLayout";
import { TiPlus } from "react-icons/ti";
import { useState, useEffect } from "react";
import { ITableColumn } from "interfaces";
import { IForwarderDetail, IFowarderData } from "interfaces/fowarder.interface";
import { IoEyeOutline } from "react-icons/io5";
import { forwarderAPI } from "api/forwarderAPI";
import { LogApp, handleFullName, showAppToast } from "utils";
import { IStatusKey, STATUS, STATUS_COLOR } from "enum";
import { FormModal } from "./FormModal";
import { defaultParams } from "../manufacture/defaultValue";
import PopoverHorizon from "components/popover";
import { PopoverBody } from "@chakra-ui/popover";
import { RadioGroup, Radio } from "@chakra-ui/radio";
import { Badge, Stack } from "@chakra-ui/react";
import { setLoading, useAppDispatch } from "store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PATH_ADMIN_FORWARDER } from "navigation";

export const Forwarder = () => {
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [forwarders, setForwarders] = useState<IFowarderData[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalResult, setTotalResult] = useState<number>();
  const [params, setParams] = useState<any>(defaultParams);
  const [forwarderDetail, setForwarderDetail] = useState<any>();
  const [newForwarder, _] = useState<IForwarderDetail>();
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [mode, setMode] = useState<"new" | "detail">();
  const [companyIndustries, setCompanyIndustries] = useState<any>();
  const [companyIndustriesWithArea, setCompanyIndustriesWithArea] =
    useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const colums: ITableColumn<IFowarderData>[] = [
    {
      key: "id",
      title: "No",
      render: (_, val: any) => (
        <div>{val.index + 1 + (params.page - 1) * 10}</div>
      ),
    },
    {
      key: "name",
      title: "Forwarder name",
    },
    {
      key: "companyName",
      title: "Company name",
    },
    {
      key: "email",
      title: "Email",
    },
    {
      key: "declineOfferNumber",
      title: "Declined",
    },
    {
      key: "status",
      title: "Status",
      render: (_, val: any) => (
        <>
          <Badge
            colorScheme={
              STATUS_COLOR[
                `${val.status?.toUpperCase() as keyof typeof STATUS_COLOR}`
              ]
            }
          >
            {val.status}
          </Badge>
        </>
      ),
    },
    {
      key: "none",
      title: "Action",
      render: (_, val2) => (
        <div className="ml-3">
          <IoEyeOutline
            className="cursor-pointer "
            onClick={() => setSearchParams({ id: `${val2?.id}` })}
          />
        </div>
      ),
    },
  ];

  const statuses = Object.keys(STATUS)
    .filter((status) => status !== "frozen")
    .map((key: string) => ({
      label: STATUS[key as IStatusKey],
      value: STATUS[key as IStatusKey],
    }));

  const getForwarders = async () => {
    try {
      setLoadingTable(true);
      const res = (await forwarderAPI.getList(params)) as any;
      if (res?.success) {
        setForwarders(
          res.data.items.map((item: any, index: number) => ({
            ...item,
            index: ++index,
            name: handleFullName(item.firstName, item.lastName),
            isActive: item.isActive ? STATUS.active : STATUS.inactive,
          }))
        );
        setTotalPage(+res.data.totalPage);
        setTotalResult(res.data.total);
      }
      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);
      LogApp(error);
    }
  };

  const onAddNew = async () => {
    await getListCountry();
    await getListCountryWithArea();
    setMode("new");
    setOpenModal(true);
  };

  const handleModal = (val: boolean) => {
    setOpenModal(val);
    getForwarders();
    navigate(PATH_ADMIN_FORWARDER);
  };

  const handleViewDetail = async (forwarderId: number) => {
    try {
      const res = await Promise.all([
        forwarderAPI.getDetal(forwarderId),
        getListCountry(),
        getListCountryWithArea(),
      ]);
      const data = res[0]?.data as any;
      LogApp(res);
      if (res[0]?.success && data) {
        setForwarderDetail({
          id: data?.id || forwarderId,
          firstName: data?.firstName || "",
          lastName: data?.lastName || "",
          companyPhoneNumber: data?.companyPhoneNumber || "",
          email: data?.email || "",
          companyName: data?.companyName || "",
          companyVatId: data?.companyVatId?.trim() || "",
          originatedCountryId: data?.originatedCountry
            ? {
                value: data?.originatedCountry?.id,
                label: data?.originatedCountry?.name,
              }
            : { value: "", label: "Select country" },
          interestedCountry:
            data?.interestedCountry?.map((item: any) => ({
              ...item,
              value: item.id,
              label: item.name,
            })) || [],
          status: {
            label: data?.status,
            value: data?.status,
          },
          companyLogo: data?.companyLogo
            ? [
                {
                  title: data?.companyLogoTitle || "",
                  url: data?.companyLogo || "",
                },
              ]
            : [],
          companyRegistrations: data.companyRegistrations?.map((item: any) => ({
            title: item?.title || "",
            url: item?.documentUrl || "",
          })),
          additionalDocument: data.additionalDocument?.map((item: any) => ({
            title: item?.title || "",
            url: item?.documentUrl || "",
          })),
          declined: data?.declineOfferNumber || 0,
        });
        setMode("detail");
        setOpenModal(true);
      }
    } catch (error) {}
  };

  const handleAddNew = async (value: IForwarderDetail) => {
    try {
      dispatch(setLoading(true));
      const {
        companyVatId,
        companyLogo,
        companyRegistrations,
        interestedCountry,
        additionalDocument,
        originatedCountryId,
        status,
        ...rest
      } = value;
      const payload = {
        status: status.value,
        ...rest,
      };
      await forwarderAPI.addNew(payload);
      await getForwarders();
      showAppToast("Sucess", "success");
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      return Promise.reject();
    }
  };

  const handleUpdateForwarder = async (value: IForwarderDetail) => {
    try {
      dispatch(setLoading(true));
      const {
        interestedCountry,
        id,
        companyRegistrations,
        additionalDocument,
        originatedCountryId,
        status,
        ...rest
      } = value;
      const payload = {
        ...rest,
        status: status.value,
        originatedCountryId: +originatedCountryId?.value || null,
        companyLogoTitle: value.companyLogo?.[0]?.title,
        companyLogo: value.companyLogo?.[0]?.url,
      } as any;
      if (companyRegistrations.length) {
        payload.companyRegistrations = companyRegistrations?.map(
          (item: any) => ({
            title: item?.title,
            documentUrl: item?.url,
          })
        );
      }
      if (additionalDocument.length) {
        payload.additionalDocument = additionalDocument?.map((item: any) => ({
          title: item?.title,
          documentUrl: item?.url,
        }));
      }
      if (value?.interestedCountry) {
        payload.interestedCountries = value?.interestedCountry.map(
          (item: any) => +item.value
        );
      }
      await forwarderAPI.update(value.id, payload);
      await getForwarders();
      showAppToast("Sucess", "success");
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      return Promise.reject();
    }
  };

  const getListCountry = async () => {
    try {
      const res = (await forwarderAPI.getListCountry()) as any;
      if (res?.success) {
        setCompanyIndustries(
          res?.data?.map((item: any) => ({
            ...item,
            value: item.id,
            label: item.name,
          }))
        );
      }
    } catch (error) {}
  };

  const getListCountryWithArea = async () => {
    try {
      const res = (await forwarderAPI.getListCountryWithArea()) as any;
      if (res?.success) {
        setCompanyIndustriesWithArea(
          res?.data?.map((item: any) => ({
            ...item,
            value: item.id,
            label: item.name,
          }))
        );
      }
    } catch (error) {}
  };

  const filterByStatus = (value: any) => {
    setLoadingTable(true);
    setForwarders([]);
    setParams((prev: any) => ({ ...prev, status: value, page: 1 }));
  };

  const handleSearch = (e: any) => {
    setLoadingTable(true);
    setForwarders([]);
    setParams((prev: any) => ({
      ...prev,
      search: e?.target?.value || "",
      page: 1,
    }));
  };

  const handlePageChange = (e: any) => {
    setLoadingTable(true);
    setForwarders([]);
    setParams((prev: any) => ({ ...prev, page: e.selected + 1 }));
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      getForwarders();
    }, 500);

    return () => clearTimeout(getData);
  }, [params]);

  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      handleViewDetail(+id);
    }
  }, [searchParams]);

  return (
    <div>
      <div className="h-full w-full">
        <div className="mb-4 flex w-full flex-row items-end justify-between">
          <div className="flex">
            <InputField
              id="Search"
              placeholder="Search"
              shouldClear={true}
              value={params?.search}
              className="min-w-[360px] max-w-[30%] border-gray-600"
              onChange={handleSearch}
            />
            <PopoverHorizon
              trigger={
                <button className="mx-2 mt-2 rounded-xl border border-gray-600 p-2 font-bold">
                  Filter
                </button>
              }
              renderContent={(onClose) => {
                return (
                  <PopoverBody>
                    <p className="mb-2 font-bold">Status:</p>
                    <RadioGroup
                      onChange={filterByStatus}
                      value={params.status}
                      onClick={onClose}
                    >
                      <Stack spacing={2} direction="row">
                        <Radio value="">All</Radio>
                        <Radio value={STATUS.active}>Active</Radio>
                        <Radio value={STATUS.inactive}>Inactive</Radio>
                        <Radio value={STATUS.frozen}>Frozen</Radio>
                      </Stack>
                    </RadioGroup>
                  </PopoverBody>
                );
              }}
            />
          </div>

          <Button
            title="Add forwarder"
            className="h-[44px] max-w-[200px]"
            icon={<TiPlus className="mr-1" />}
            onClick={onAddNew}
          />
        </div>
        <TableLayout
          columns={colums}
          data={forwarders}
          onDoubleClick={(value) => setSearchParams({ id: `${value?.id}` })}
          loading={loadingTable}
        />
        {!!totalResult && !loadingTable && (
          <div className="mx-3 flex items-center justify-between">
            <div>
              {totalResult > 1
                ? `${totalResult} results`
                : `${totalResult} result`}{" "}
            </div>
            <Pagination
              totalPage={totalPage}
              onChangePage={handlePageChange}
              forcePage={params.page - 1}
            />
          </div>
        )}
      </div>
      {mode === "new" ? (
        <FormModal
          title={"Add new Forwarder"}
          updateOpenModal={handleModal}
          isOpen={isOpenModal}
          data={newForwarder}
          industries={companyIndustries}
          industriesWithArea={companyIndustriesWithArea}
          statuses={statuses}
          onSave={handleAddNew}
          mode={mode}
        />
      ) : (
        <FormModal
          title={"Forwarder detail"}
          updateOpenModal={handleModal}
          isOpen={isOpenModal}
          data={forwarderDetail}
          industries={companyIndustries}
          industriesWithArea={companyIndustriesWithArea}
          statuses={statuses}
          onSave={handleUpdateForwarder}
          mode={mode}
        />
      )}
    </div>
  );
};
