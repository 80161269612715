import { PATH_SIGN_IN } from "navigation";
import { Navigate, Outlet } from "react-router-dom";
import { selectAuth, useAppSelector } from "store";

export const AppRoute = () => {
  const { accessToken } = useAppSelector(selectAuth);
  // if (import.meta.env.DEV) {
  //   return <Outlet />;
  // }

  return accessToken ? <Outlet /> : <Navigate to={PATH_SIGN_IN} />;
};
