import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button } from "@chakra-ui/react";
import { AppText } from "components/styled/AppStyled";
import React from "react";

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
};

export default function ModalConfirm(props: Props) {
  const { isOpen, onClose, onConfirm } = props;

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="!max-w-max items-center overflow-auto p-10 px-16">
        <ModalCloseButton className="" />
        {/* reder iframe thar show a pdf base 64 */}
        <ModalHeader>
          <AppText className="text-3xl font-semibold">Confirm</AppText>
        </ModalHeader>
        <ModalBody className="w-max ">
          <AppText className="w-max text-center font-medium">
            Are you sure you want to change this invoice to paid?
          </AppText>
          <div className="mt-10 flex w-full justify-center gap-6">
            <Button variant="outline" onClick={onClose}>
              Close
            </Button>
            <Button onClick={onConfirm} colorScheme="blue" mr={3} type="submit">
              Save
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
