import { Popover, PopoverTrigger, PopoverContent } from "@chakra-ui/popover";
const PopoverHorizon = (props: {
  trigger: JSX.Element;
  extra?: string;
  renderContent: (onClose?: () => void) => JSX.Element;
  onClose?: () => void;
}) => {
  const { extra, trigger, renderContent, onClose } = props;
  return (
    <Popover onClose={onClose}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>{trigger}</PopoverTrigger>
          <PopoverContent
            className={`w-max rounded-xl bg-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none ${extra}`}
          >
            {renderContent(onClose)}
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default PopoverHorizon;
