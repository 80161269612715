import { profileApi } from "api";
import avatarIcon from "assets/img/profile/avatar.png";
import { useEffect, useState } from "react";
import { Button } from "components/button/Button";
import styled from "styled-components";
import { InputController } from "components/controller/InputController";
import { useForm } from "react-hook-form";
import { uploadAPI } from "api/uploadAPI";
import { yupResolver } from "@hookform/resolvers/yup";
import { profileSchema } from "schema/profile.schema";
import { showAppToast } from "utils";

interface IUserInfo {
  firstName: string;
  lastName: string;
  companyPhoneNumber: string;
  avatarUrl: string;
  email?: string;
}

const defaultValues = {
  firstName: "",
  lastName: "",
  companyPhoneNumber: "",
  avatarUrl: "",
  email: "",
};

const UserInfo = () => {
  const [userInfo, setUserInfo] = useState<IUserInfo>();
  const [isEdit, setEdit] = useState<Boolean>(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    clearErrors
  } = useForm({
    resolver: yupResolver(profileSchema),
    defaultValues,
  });

  const getProfileInfo = async () => {
    try {
      const res = (await profileApi.getDetailInfo()) as any;
      const info = {} as any;
      [
        "firstName",
        "lastName",
        "companyPhoneNumber",
        "avatarUrl",
        "email",
      ].forEach((key: any) => {
        info[key] = res.data?.[key];
        setValue(key, res.data?.[key]);
      });

      setUserInfo(info);
    } catch (error) {}
  };
  const onChangeAvatar = async (e: any) => {
    try {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const res = await uploadAPI.uploadFile(formData);
      if (res.data?.url) {
        setUserInfo((prev) => ({ ...prev, avatarUrl: res.data.url }));
      }
      return;
    } catch (error) {}
  };
  const handleUpdateProfile = async (data: typeof defaultValues) => {
    try {
      const { email, ...payload } = data;
      payload.avatarUrl = userInfo.avatarUrl;
      await profileApi.updateProfile(payload);
      onCloseEdit();
      showAppToast("Sucess", "success");
    } catch (error) {}
  };
  const onCloseEdit = () => {
    clearErrors()
    setEdit(false);
    getProfileInfo();
  };

  useEffect(() => {
    getProfileInfo();
  }, []);

  return (
    <>
      <Wrapper>
        {userInfo && (
          <div className="col-span-2 ">
            {/* <div className="mb-3 max-w-lg">
              <label className="label flex justify-center ">
                <input
                  type={isEdit ? "file" : "hidden"}
                  className="hidden"
                  onChange={onChangeAvatar}
                />
                <div className="avatar w-fit ">
                  <img
                    src={userInfo.avatarUrl || avatarIcon}
                    className={`h-16	w-16	rounded-full object-cover ${
                      isEdit && "cursor-pointer"
                    }`}
                  />
                  <div className="edit-avatar"></div>
                </div>
              </label>
            </div> */}
            <div className="mt-5 max-w-lg justify-between">
              <div className="text-left">
                <div className="font-bold">First Name</div>
                <InputController
                  control={control}
                  name={"firstName"}
                  disabled={!isEdit}
                  defaultValue="dfasdfasdf"
                  error={errors.firstName?.message}
                />
              </div>
            </div>
            <div className="mt-5 max-w-lg justify-between">
              <div className="text-left">
                <div className="font-bold">Last Name</div>
                <InputController
                  control={control}
                  name={"lastName"}
                  disabled={!isEdit}
                  defaultValue="dfasdfasdf"
                  error={errors.lastName?.message}
                />
              </div>
            </div>
            <div className="mt-5 max-w-lg justify-between">
              <div className="text-left">
                <div className="font-bold">Phone Number</div>
                <InputController
                  control={control}
                  name={"companyPhoneNumber"}
                  disabled={!isEdit}
                  defaultValue="dfasdfasdf"
                  error={errors.companyPhoneNumber?.message}
                />
                <div></div>
              </div>
            </div>
            <div className="mt-5 max-w-lg justify-between">
              <div className="text-left">
                <div className="font-bold">Email</div>
                <InputController
                  control={control}
                  name={"email"}
                  disabled={true}
                  defaultValue="dfasdfasdf"
                />

                <div></div>
              </div>
            </div>
            {isEdit ? (
              <div className="mt-8 flex max-w-lg	justify-center">
                <div className="mx-2 w-60">
                  <Button title="Cancel" onClick={onCloseEdit}></Button>
                </div>
                <div className="mx-2 w-60">
                  <Button
                    title="Save"
                    onClick={handleSubmit(handleUpdateProfile)}
                  ></Button>
                </div>
              </div>
            ) : (
              <div className="mt-8 flex max-w-lg justify-center	">
                <div className="mx-2 w-60">
                  <Button title="Edit" onClick={() => setEdit(true)}></Button>
                </div>
              </div>
            )}
          </div>
        )}
      </Wrapper>
    </>
  );
};

export const Wrapper = styled.div`
  .avatar {
  }
`;

export default UserInfo;
