import { API_ENDPOINT } from "enum";
import { AxiosService } from "./axiosService";

interface IProfileUpdate {
  firstName: string;
  lastName: string;
  companyPhoneNumber: string;
  avatarUrl: string;
}

export const profileApi = {
  getDetailInfo: () => {
    const url = API_ENDPOINT.PROFILE;
    return AxiosService.get(url);
  },
  updateProfile: (body: IProfileUpdate) => {
    const url = API_ENDPOINT.PROFILE;
    return AxiosService.put(url, body);
  },
};
