import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { type RootState } from "../../store";
import { AUTH_TOKEN } from "config";
import { removeFormLS } from "utils";
import { debug, log } from "console";

interface IAuthState {
  accessToken?: string;
  refreshToken?: string;
  accountInfo?: any;
  forgotEmail?: string;
  verifyMailHash?: string;
  resetPassHash?: string;
}

const initialState: IAuthState = {
  accessToken: undefined,
  refreshToken: undefined,
  accountInfo: undefined,
  verifyMailHash: undefined,
  resetPassHash: undefined,
  forgotEmail: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setAccountInfo: (state, action: PayloadAction<any>) => {
      state.accountInfo = action.payload;
    },
    setVerifyMailHash: (state, action: PayloadAction<string>) => {
      state.verifyMailHash = action.payload;
    },
    setResetPassHash: (state, action: PayloadAction<string>) => {
      state.resetPassHash = action.payload;
    },
    setForgotEmail: (state, action: PayloadAction<string>) => {
      state.forgotEmail = action.payload;
    },
    logout: () => initialState,
  },
});

export const selectAuth = (state: RootState) => state.auth;

export const {
  setAccessToken,
  setAccountInfo,
  setVerifyMailHash,
  setResetPassHash,
  logout,
  setForgotEmail,
} = authSlice.actions;
export default authSlice.reducer;
