import ReactPaginate from "react-paginate";
import styled from "styled-components";

export const Pagination = (props: any) => {
  const { totalPage, onChangePage, forcePage } = props;

  return (
    <Wrapper>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">>"
        onPageChange={onChangePage}
        pageRangeDisplayed={5}
        pageCount={totalPage}
        forcePage={forcePage}
        previousLabel="<<"
        renderOnZeroPageCount={null}
      />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  ul {
    margin-bottom: 2rem;
    margin-top: 1rem;
    display: flex;
    justify-content: end;
    flex-direction: row;
    list-style-type: none;
  }
  li {
    margin: 0 2px;
  }

  li a {
    padding: 0.1rem 1rem;
    cursor: pointer;
  }

  li.selected a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
  }

  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.active a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;
