import { API_ENDPOINT } from "enum";
import { AxiosService } from "./axiosService";

export const auctionAPI = {
  getList: (params: any) => {
    const url = API_ENDPOINT.AUCTION;
    return AxiosService.get(url, { params });
  },
  getDetail: (id: number) => {
    const url = `${API_ENDPOINT.AUCTION}/${id}`;
    return AxiosService.get(url);
  },
};
