import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { type RootState } from "../../store";
import { appLanguageEnum } from "config";

interface IAppState {
  mobile: boolean;
  loading: boolean;
  sidebarCollapsed: boolean;
  currentPage: number;
  language: appLanguageEnum;
}

const initialState: IAppState = {
  mobile: false,
  loading: false,
  sidebarCollapsed: false,
  currentPage: 1,
  language: appLanguageEnum.EN,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setMobile: (state, action: PayloadAction<boolean>) => {
      state.mobile = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSidebarCollapsed: (state, action: PayloadAction<boolean>) => {
      state.sidebarCollapsed = action.payload;
    },
    setAppLanguage: (state, action: PayloadAction<appLanguageEnum>) => {
      state.language = action.payload;
    },
  },
});

export const selectApp = (state: RootState) => state.app;

export const { setSidebarCollapsed, setLoading, setMobile, setAppLanguage } =
  appSlice.actions;
export default appSlice.reducer;
