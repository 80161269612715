import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IAppState {
  newNotification: any | null;
}

const initialState: IAppState = {
  newNotification: null,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNewNotification: (state, action: PayloadAction<any>) => {
      state.newNotification = action.payload;
    },
  },
});

export const { setNewNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
