import { API_ENDPOINT } from "enum";
import { AxiosService } from "./axiosService";
import { IContactUsData, TPaging } from "interfaces";

export const contactUsAPI = {
  getListContactUs: (params?: any) => {
    const url = API_ENDPOINT.CONTACT_US;
    return AxiosService.get<TPaging<IContactUsData>>(url, { params });
  },
  getContactUsDetail: (id: number) => {
    const url = `${API_ENDPOINT.CONTACT_US}/${id}`;
    return AxiosService.get<IContactUsData>(url);
  },
};
