import styled, { css } from "styled-components";

export const AppText = styled.p<{
  $color?: string;
  $fontSize?: number | string;
  $lineHeight?: number | string;
  $fontWeight?: number;
  $letterSpacing?: number;
  $fontStyle?: "italic" | "normal" | "oblique" | "initial";
}>`
  margin: 0;
  color: ${(p) => p.theme.colors.neutral800};
  font-family: "Poppins", sans-serif;
  ${(p) =>
    p?.$color &&
    css`
      color: ${p.$color};
    `};
  ${({ $fontSize }) =>
    $fontSize &&
    css`
      font-size: ${typeof $fontSize === "number"
        ? `${$fontSize}px`
        : $fontSize};
    `};
  ${({ $lineHeight }) =>
    $lineHeight &&
    css`
      line-height: ${typeof $lineHeight === "number"
        ? `${$lineHeight}px`
        : $lineHeight};
    `};
  ${(p) =>
    p?.$fontWeight &&
    css`
      font-weight: ${p.$fontWeight};
    `};
  ${(p) =>
    p?.$letterSpacing &&
    css`
      letter-spacing: ${p.$letterSpacing}px;
    `};
  ${(p) =>
    p?.$fontStyle &&
    css`
      font-style: ${p.$fontStyle};
    `};
`;

export const FlexWrap = styled.div<{
  $gap?: number | string;
  $height?: number | string;
  $align?: string;
  $justifyContent?: string;
  $fullW?: boolean;
  $mt?: number | string;
  $direction?: "row-reverse" | "row" | "column" | "column-reverse";
}>`
  display: flex;
  align-items: center;
  ${({ $fullW }) =>
    $fullW &&
    typeof $fullW &&
    css`
      width: 100%;
    `};
  ${({ $gap }) =>
    $gap &&
    (typeof $gap === "number"
      ? css`
          gap: ${$gap}px;
        `
      : css`
          gap: ${$gap};
        `)};
  ${({ $height }) =>
    $height &&
    (typeof $height === "number"
      ? css`
          height: ${$height}px;
        `
      : css`
          height: ${$height};
        `)};
  ${({ $align }) =>
    $align &&
    css`
      align-items: ${$align};
    `};
  ${({ $justifyContent }) =>
    $justifyContent &&
    css`
      justify-content: ${$justifyContent};
    `};
  ${({ $direction }) =>
    $direction &&
    css`
      flex-direction: ${$direction};
    `};
  ${({ $mt }) =>
    $mt &&
    (typeof $mt === "number"
      ? css`
          margin-top: ${$mt}px;
        `
      : css`
          margin-top: ${$mt};
        `)};
`;

export const DesktopView = styled.div`
  width: 100%;
  @media (max-width: 480px) {
    display: none;
  }
`;

export const MobileView = styled.div`
  width: 100%;
  @media (min-width: 480px) {
    display: none;
  }
`;

export const Box = styled.div<{
  $height?: number | string;
  $width?: number | string;
  $mt?: number | string;
  $mb?: number | string;
  $color?: string;
}>`
  width: 100%;
  ${({ $width }) =>
    $width &&
    (typeof $width === "number"
      ? css`
          width: ${$width}px;
        `
      : css`
          width: ${$width};
        `)};
  ${({ $height }) =>
    $height &&
    (typeof $height === "number"
      ? css`
          height: ${$height}px;
        `
      : css`
          height: ${$height};
        `)};
  ${({ $mt }) =>
    $mt &&
    (typeof $mt === "number"
      ? css`
          margin-top: ${$mt}px;
        `
      : css`
          margin-top: ${$mt};
        `)};
  ${({ $mb }) =>
    $mb &&
    (typeof $mb === "number"
      ? css`
          margin-bottom: ${$mb}px;
        `
      : css`
          margin-bottom: ${$mb};
        `)};
  ${({ $color }) =>
    $color &&
    css`
      background: ${$color};
    `};
`;
