/* eslint-disable */
import { HiX } from "react-icons/hi";
import logoImg from "assets/img/logo.png";
import Links from "./components/Links";
import { sideBarRoutes } from "navigation/routes";
import { PATH_ADMIN_DASHBOARD } from "navigation";
import SidebarCard from "./components/SidebarCard";

const Sidebar = (props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
}) => {
  const { open, onClose } = props;
  return (
    <div
      className={`sm:none duration-175 linear fixed w-64 !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`flex items-center`}>
        <a
          href={PATH_ADMIN_DASHBOARD}
          className="mx-auto w-40	px-3 py-8 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white"
        >
          <img src={logoImg} alt="" />
        </a>
      </div>
      {/* Nav item */}
      <ul className="mb-auto pt-1">
        <Links routes={sideBarRoutes} />
      </ul>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
