export const getValue = (data: any, key: string) => {
  return data?.[key] || "";
};

export const convertAceptFile = (acceptFile?: any) => {
  if (!acceptFile) return {};

  const acceptFileMap: Record<string, string[]> = {};
  acceptFile.map((item: any) => {
    acceptFileMap[item[0]] = item[1];
  });
  return acceptFileMap;
};
