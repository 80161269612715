export const manufactureDefault = {
  id: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  companyName: "",
  companyVatId: "",
  companyAddress: "",
  companyIndustryId: "",
  status: "",
  companyLogo: "",
};
export const defaultParams = {
  page: 1,
  limit: 10,
  search: null as any,
  sort: null as any,
  status: "",
};
