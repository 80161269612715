import React from 'react';
import styled, { css } from 'styled-components';

import { APP_HEADER_HEIGHT } from 'config';
import { AppSpin } from './AppSpin';

interface IProps {
  isInnerPage?: boolean;
}

export const PageLoading: React.FC<IProps> = props => {
  const { isInnerPage = false } = props;

  return (
    <PageStyled
      $isInnerPage={isInnerPage}
      className="loading-page"
    >
      <AppSpin
        borderSize={5}
        size={75}
      />
    </PageStyled>
  );
};

const PageStyled = styled.main<{ $isInnerPage: boolean }>`
  width: 100dvw;
  height: 100vh;
  height: 100dvh;
  ${props =>
    props.$isInnerPage &&
    css`
      width: 100%;
      height: calc(100vh - ${APP_HEADER_HEIGHT});
      height: calc(100dvh - ${APP_HEADER_HEIGHT});
    `};
  display: flex;
  align-items: center;
  justify-content: center;
`;
