import React, { useState } from 'react';
import cn from 'classnames';
import styled, { css } from 'styled-components';
import { IMAGES } from 'assets/img';
import { borderRadiusEnum } from 'config';

interface IProps {
  borderRadius?: borderRadiusEnum;
  width?: number;
  height?: number;
  size?: number;
  src?: string | null;
  alt?: string;
  containerClassName?: string;
  imgClassName?: string;
  containerStyles?: React.CSSProperties;
  imgStyles?: React.CSSProperties;
  onClick?: () => void;
  onLoad?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  showError?: boolean;
  shape?: 'default' | 'circle';
  draggable?: boolean;
}

export const AppImage = React.memo<IProps>(props => {
  const {
    borderRadius,
    width,
    height,
    size,
    src,
    alt,
    containerClassName,
    imgClassName,
    onClick,
    containerStyles,
    imgStyles,
    onLoad,
    showError = true,
    shape = 'default',
    draggable = false
  } = props;

  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  return (
    <Container
      $borderRadius={borderRadius}
      $shape={shape}
      $height={height}
      $width={width}
      $size={size}
      className={cn('app-image-container', containerClassName)}
      onClick={onClick}
      style={containerStyles}
    >
      <img
        className={cn(
          'app-image smooth-image',
          imageLoaded ? 'dh-image' : 'image-hidden',
          imgClassName
        )}
        src={!isError ? src ?? IMAGES.defaultImage : IMAGES.errorImage}
        alt={alt ?? 'no_image'}
        onLoad={e => {
          onLoad && onLoad(e);
          setImageLoaded(true);
        }}
        onError={() => {
          showError && setIsError(true);
        }}
        style={imgStyles}
        draggable={draggable}
        loading="lazy"
      />
      {!imageLoaded && <Skeleton className="pre-loader" />}
    </Container>
  );
});

AppImage.displayName = 'AppImage';

const Container = styled.div<{
  $width?: number;
  $height?: number;
  $size?: number;
  $shape?: 'default' | 'circle';
  $borderRadius?: borderRadiusEnum;
}>`
  ${props =>
    props.$borderRadius &&
    css`
      border-radius: ${props.$borderRadius}px;
    `};
  ${props =>
    props.$shape === 'circle' &&
    css`
      border-radius: 100%;
    `};
  overflow: hidden;
  width: ${props => (props.$width ? `${props.$width}px` : '100%')};
  height: ${props => (props.$height ? `${props.$height}px` : '100%')};

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `};

  ${({ $size }) =>
    $size &&
    css`
      width: ${$size}px;
      height: ${$size}px;
    `}
  & > .app-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  & > .smooth-image {
    transition: opacity 1s;
  }

  & > .dh-image {
    opacity: 1;
  }

  & > .image-hidden {
    opacity: 0;
  }
`;

const Skeleton = styled.div`
  width: 100%;
  height: 100%;
  background-color: #eee;
  background-image: linear-gradient(
    110deg,
    #ececec 8%,
    #f5f5f5 18%,
    #ececec 33%
  );
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;
