import React from "react";
import { IconMap } from "components/icons";

export type TIconMap = keyof typeof IconMap;
interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: keyof typeof IconMap;
  size?: string | number;
  color?: string;
  pdf?: string;
}

export const AppIcon: React.FC<IconProps> = ({
  name,
  width = "24",
  height = "24",
  size = "16",
  ...rest
}) => {
  const MatchIcon = IconMap[name];

  return <MatchIcon width={size || width} height={size || height} {...rest} />;
};
