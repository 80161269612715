import { emailSchema, phoneNumberSchema, requireTextSchema } from "./schema";
import * as yup from "yup";

export const manufactureSchema = (mode: "new" | "detail") => {
  switch (mode) {
    case "new":
      return yup.object().shape({
        firstName: requireTextSchema("First name is required"),
        lastName: requireTextSchema("Last name is required"),
        companyPhoneNumber: phoneNumberSchema(
          "Company phone number is required"
        ),
        email: emailSchema("Company email is required"),
        companyName: requireTextSchema("Company name is required"),
        companyVatId: yup.string(),
        companyIndustryId: yup
          .object()
          .shape({ value: yup.string(), label: yup.string() }),
        status: yup.object().required("This field is required"),
        companyIndustryOther: yup.string(),
        companyLogo: yup.array(),
        companyRegistrations: yup.array(),
      });
    case "detail":
      return yup.object().shape({
        firstName: requireTextSchema("This field is required"),
        lastName: requireTextSchema("This field is required"),
        companyPhoneNumber: phoneNumberSchema(),
        email: emailSchema(),
        companyName: requireTextSchema("This field is required"),
        companyVatId: yup.string().required("This field is required"),
        companyIndustryId: yup.object().shape({
          value: yup.string().required("This field is required"),
          label: yup.string(),
        }),
        companyIndustryOther: yup.string().when("companyIndustryId", (val) => {
          return val[0].label === "Others"
            ? yup.string().required("This field is required")
            : yup.string();
        }),
        status: yup.object().required("This field is required"),
        companyLogo: yup.array().min(1, "This field is required"),
        companyRegistrations: yup.array().min(1, "This field is required"),
      });
    default:
      break;
  }
};
