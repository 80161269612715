import { Routes } from "react-router";
import { BrowserRouter, Navigate, Route } from "react-router-dom";
import { Fragment, Suspense, useEffect } from "react";
import { AppSpin } from "components/loading/AppSpin";
import { useAppSelector, selectApp } from "store";
import { AuthRoute } from "./route/AuthRoute";
import { PageLoading } from "components/loading/PageLoading";
import { AppRoute } from "./route/AppRoute";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import ProfileLayout from "layouts/profile";

export const MainLayout: React.FC = () => {
  const { loading, language } = useAppSelector(selectApp);

  return (
    <Fragment>
      {loading && <AppSpin hasOverlay />}
      <Suspense fallback={<PageLoading />}>
        <Routes>
          {/* Main Route */}
          <Route path="/" element={<AppRoute />}>
            <Route path="admin/*" element={<AdminLayout />} />
            <Route path="profile/*" element={<ProfileLayout />} />
            <Route path="/" element={<Navigate to="/auth" />} />
          </Route>
          {/* Auth Route */}
          <Route path="/" element={<AuthRoute />}>
            <Route path="/auth/*" element={<AuthLayout />} />
          </Route>
        </Routes>
      </Suspense>
    </Fragment>
  );
};
